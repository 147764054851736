<script setup lang="ts">
import { computed } from 'vue'
import Circle from '@/components/Circle.vue'

const props = defineProps<{
   strokeWidth: number
   speed: number
   percent: number
   width: number
   color: string
}>()

const widthSVG = computed(() => props.width / 0.92)
</script>

<template>
   <div class="absolute" :style="`width: ${widthSVG}px; height: ${widthSVG}px`">
      <svg class="svg -rotate-90" :stroke-width="strokeWidth">
         <Circle :speed="speed" :percent="percent" :width="widthSVG" :color="color" />
      </svg>
      <svg class="svg -scale-x-100 rotate-90" :stroke-width="strokeWidth">
         <Circle :speed="speed" :percent="percent" :width="widthSVG" :color="color" />
         <defs>
            <slot />
         </defs>
      </svg>
   </div>
</template>

<style scoped>
.svg {
   stroke-linecap: round;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   width: 100%;
   height: 100%;
}
</style>
