<script setup lang="ts">
import { ref, computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { getFutureAndLiveMatches } from '@/gql/requests'
import LiveMatch from '@/components/Match/Live.vue'
import MatchesHeader from '@/components/Match/MatchesHeader.vue'
import type { FilterMatches } from '@/types/matches'

const { result } = useQuery(
   getFutureAndLiveMatches,
   {},
   {
      fetchPolicy: 'cache-only'
   }
)

const existMatches = computed(() => result.value?.matches.some((match) => match.hasPredict && match.status === 'LIVE'))

const activeHeader = ref(true)
const statusFilter = ref<FilterMatches>('all')

const matches = computed(() => {
   if (statusFilter.value === 'all') {
      return result.value?.matches.filter((match) => match.hasPredict && match.status === 'LIVE') ?? []
   } else {
      return (
         result.value?.matches.filter(
            (match) =>
               match.hasPredict &&
               match.status === 'LIVE' &&
               (statusFilter.value === 'recommended' ? match.recommended : !match.recommended)
         ) ?? []
      )
   }
})
</script>

<template>
   <section v-if="existMatches" class="grid gap-y-20px <laptop:px-16px">
      <MatchesHeader v-model="activeHeader" v-model:filter="statusFilter" :count="matches.length">{{
         $t('matches.live.title')
      }}</MatchesHeader>
      <div v-if="activeHeader" class="grid grid-cols-3 <laptop:grid-cols-1 gap-20px <fullhd:gap-16px">
         <LiveMatch v-for="match in matches" :key="match.id" :match="match" />
      </div>
   </section>
</template>

<style scoped></style>
