<script setup lang="ts">
import { computed } from 'vue'
const props = defineProps<{
   color: 'yellow' | 'red' | 'green'
   icon: string
   iconTop?: boolean
}>()

const colorClasses = computed(() => {
   switch (props.color) {
      case 'yellow': {
         return 'icon-yellow'
      }

      case 'green': {
         return 'icon-green'
      }

      case 'red': {
         return 'icon-red'
      }

      default: {
         return 'icon-yellow'
      }
   }
})
</script>

<template>
   <div class="flex gap-x-12px" :class="iconTop ? 'items-start' : 'items-center'">
      <div
         class="flex-shrink-0 w-32px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25 relative"
         :class="colorClasses"
      >
         <div class="absolute inset-0px rounded-inherit icon-glow"></div>
         <i class="text-16px icon-color" :class="icon"></i>
      </div>
      <slot />
   </div>
</template>

<style scoped>
.icon-yellow {
   .icon-glow {
      background: radial-gradient(50% 51.14% at 50% 100%, rgba(241, 185, 10, 0.1) 0%, rgba(29, 30, 36, 0) 100%);
   }
   .icon-color {
      --uno: 'text-yellow-700';
   }
}

.icon-red {
   .icon-glow {
      background: radial-gradient(50% 51.14% at 50% 100%, rgba(223, 28, 72, 0.1) 0%, rgba(29, 30, 36, 0) 100%);
   }
   .icon-color {
      --uno: 'text-red-700';
   }
}

.icon-green {
   .icon-glow {
      background: radial-gradient(50% 51.14% at 50% 100%, rgba(44, 194, 86, 0.16) 0%, rgba(29, 30, 36, 0) 100%);
   }
   .icon-color {
      --uno: 'text-green-500';
   }
}
</style>
