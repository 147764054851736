<script setup lang="ts">
import * as amplitude from '@/composables/amplitude'
import Button from '@/components/UI/Button.vue'

const handler = () => {
   amplitude.track('click.create-diary', 'dashboard')
}
</script>

<template>
   <article
      class="relative z-1 bg-radial bg-black-850 rounded-10px px-24px py-30px <laptop:px-12px <laptop:pt-16px <laptop:pb-12px flex items-center justify-between <laptop:flex-col <laptop:items-start <laptop:gap-y-28px overflow-hidden"
   >
      <div class="flex flex-col gap-y-16px">
         <h3>
            {{ $t('RAlN2g0R2d0Wrqx8BDZWV') }} <br class="hidden <laptop:block" />
            {{ $t('aGn8aY7uYqQBSLBD6nAZM') }}<br class="<laptop:hidden" />
            {{ $t('a8zsiN0npCbZRLGJu-otI') }} <br class="hidden <laptop:block" />
            {{ $t('fb30N5rwZa9sKAz6cF8-r') }}
         </h3>
         <ul class="s-medium2 flex flex-col gap-y-4px">
            <li class="flex items-center gap-x-12px">
               <div class="flex-shrink-0 w-6px h-6px bg-yellow-700 rounded-full shadow-yellow"></div>
               <span
                  >{{ $t('H7QAClJ6hMWlF-A2ZZNgW') }} <br class="hidden <laptop:block" />
                  {{ $t('Pqljau29tHbcDzvi-Cn5N') }}</span
               >
            </li>
            <li class="flex items-center gap-x-12px">
               <div class="flex-shrink-0 w-6px h-6px bg-yellow-700 rounded-full shadow-yellow"></div>
               <span
                  >{{ $t('Oh7tNjiYFTkxrIBIhRgGA') }} <br class="hidden <laptop:block" />
                  {{ $t('go4ESa-BOLwJ0AM95IrHU') }}</span
               >
            </li>
            <li class="flex items-center gap-x-12px">
               <div class="flex-shrink-0 w-6px h-6px bg-yellow-700 rounded-full shadow-yellow"></div>
               <span>{{ $t('S1bOnHc9MAqfiJOs-1KY9') }}<br class="hidden <laptop:block" />{{ $t('ibmmDySlY2s48XyLpRzRA') }}</span>
            </li>
         </ul>
      </div>
      <div class="relative w-280px <laptop:w-full">
         <div class="absolute inset-0 bg-black-850 rounded-10px"></div>

         <a href="https://docs.google.com/spreadsheets/d/1PcLkTnU0My-KkSy-5cG5XET6RMbBg8ZzeHQly-MQzUA/edit?usp=sharing" target="_blank">
            <Button class="w-full h-52px m-semibold" @click="handler">
               {{ $t('cDqVPq5DAmXFlfwhgXskj') }}
            </Button>
         </a>
      </div>

      <picture class="absolute bottom-0 right-0 laptop:left-0 <laptop:-z-1 pointer-events-none">
         <source
            media="(max-width: 1439px)"
            srcset="/img/banners/create-diary-money-mobile@2.png 2x, /img/banners/create-diary-money-mobile.png 1x"
         />
         <img
            srcset="/img/banners/create-diary-money@2.png 2x"
            src="/img/banners/create-diary-money.png"
            alt=""
            class="laptop:absolute laptop:left-45% laptop:bottom-0"
         />
      </picture>
   </article>
</template>

<style scoped>
.bg-radial::before {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: radial-gradient(89.14% 75.82% at 69.03% 125.82%, rgba(222, 58, 30, 0.1) 0%, rgba(30, 31, 37, 0) 100%);
   z-index: -1;
}
@media (min-width: 1440px) {
   .bg-radial::after {
      content: '';
      position: absolute;
      top: 0;
      left: 35%;
      background-image: url('/img/banners/create-diary-bg.svg');
      width: 337px;
      height: 184px;
      opacity: 0.7;
      z-index: -1;
   }
}
</style>
