import { locale } from '@/i18n'
import { useGlobalStore } from '@/stores/global'
import { useMutation } from '@vue/apollo-composable'
import { addCard as addCardGraphql, Method } from '@/gql/requests'

export function useAddCard() {
   const { mutate, loading, error } = useMutation(addCardGraphql)
   const globalStore = useGlobalStore()

   const addCardMutation = async () => {
      try {
         const result = await mutate({
            method: locale.value === 'ru' ? Method.Ukassa : Method.Stripe
         })

         setTimeout(function () {
            window.location.href = result?.data?.addCard.data ?? '/'
         }, 0)
      } catch (error) {
         globalStore.notificationAdd({
            type: 'error',
            text: error instanceof Error ? error.message ?? 'Unknown error' : 'Unknown error'
         })
      }
   }

   return { addCardMutation, loading, error }
}
