<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { i18nRoute, locale } from '@/i18n'
import { useUserStore, useGlobalStore } from '@/stores'
import { useIntervalFn } from '@vueuse/core'
import Button from '@/components/UI/Button.vue'
import SwiperControls from '@/components/SwiperControls.vue'
import * as amplitude from '@/composables/amplitude'
import { useMutation } from '@vue/apollo-composable'
import { addCard, Method } from '@/gql/requests'

type Stats = {
   coefficient: number
   matches: number
   profit: number
   recommended: number
   risk: number
   successful_matches: number
   users: number
   successful_matches_last100: number
}

const router = useRouter()
const userStore = useUserStore()
const storeGlobal = useGlobalStore()
const maxCountSlide = userStore.userNew ? 1 : 4
const activeSlide = ref(1)

const { mutate: mutateAddCard } = useMutation(addCard)

const { resume } = useIntervalFn(() => {
   activeSlide.value = activeSlide.value === maxCountSlide ? 1 : activeSlide.value + 1
}, 10_000)

const buttonAccess = (numberBanner: number) => {
   amplitude.track(`click.banner-${numberBanner}`, 'banner')
   if (userStore.userUnReg) {
      return router.push(i18nRoute({ name: 'signout', query: { next: 'sub' } }))
   }

   if (userStore.user) {
      tryDatalounaHandler()
   }
}

const selectedSlide = (index: number) => {
   activeSlide.value = index
   resume()
}

const nextSlide = () => {
   activeSlide.value = activeSlide.value === 1 ? maxCountSlide : activeSlide.value - 1
   resume()
}

const prevSlide = () => {
   activeSlide.value = activeSlide.value === maxCountSlide ? 1 : activeSlide.value + 1
   resume()
}

const tryDatalounaHandler = () => {
   amplitude.track('click.choice-payment-provider', 'onboarding', locale.value === 'ru' ? 'ukassa' : 'stripe')
   mutateAddCard({
      method: locale.value === 'ru' ? Method.Ukassa : Method.Stripe
   })
      .then((result) => {
         setTimeout(function () {
            window.location.href = result?.data?.addCard.data ?? '/'
         }, 0)
      })
      .catch((error) => {
         storeGlobal.notificationAdd({
            type: 'error',
            text: error.message ?? 'Unknown error'
         })
      })
}

const userCount = ref(0)
onMounted(() => {
   fetch('https://api.datalouna.io/api/stat/site-stats')
      .then((result) => result.json())
      .then((result) => {
         if (!result.error) {
            const data = result as Stats
            userCount.value = data.users
         }
      })
      .catch((error) => {
         console.log(error)
      })
})
</script>

<template>
   <article class="bg-black-850 rounded-10px h-404px <tablet:h-424px relative z-1 <tablet:mb-20px">
      <section
         class="flex flex-col items-start justify-center rounded-inherit absolute inset-0 pl-48px duration-500 <tablet:px-12px <tablet:pt-16px <tablet:pb-8px"
         :class="activeSlide === 1 ? 'opacity-100' : 'opacity-0 invisible'"
      >
         <picture class="rounded-inherit absolute inset-0 -z-1 pointer-events-none <tablet:bottom-68px <tablet:rounded-b-none">
            <source media="(max-width: 767px)" srcset="/img/banners/gl4m.jpg 1x, /img/banners/gl4m@2.jpg 2x" />
            <img
               srcset="/img/banners/gl4@2.jpg 2x"
               src="/img/banners/gl4.jpg"
               alt="gl4"
               class="rounded-inherit w-full h-full object-cover"
            />
         </picture>

         <h1 class="h2-semibold mb-16px <tablet:h3-semibold">
            <div class="text-yellow-700">{{ $t('7s_Mts-dppBNMneYYfb7B') }}</div>
            {{ $t('j8x6MwF2O2KE7fuzkuaSj') }}
         </h1>
         <div class="m-medium <tablethd:s-medium2 <tablet:flex-auto max-w-256px">
            {{ $t('vQBJjMQqA1CiLHJ9Ubpng') }}
         </div>

         <Button
            class="min-w-240px m-semibold <tablet:l-semibold py-16px px-24px mt-24px <tablet:px-6px <tablet:w-full"
            @click="buttonAccess(1)"
         >
            {{ $t('UqQRQ-HNemNtBxkfE7-LB') }}
         </Button>
      </section>
      <section
         class="flex flex-col items-start justify-center rounded-inherit absolute inset-0 pl-48px duration-500 <tablet:px-12px <tablet:pt-16px <tablet:pb-8px"
         :class="activeSlide === 2 ? 'opacity-100' : 'opacity-0 invisible'"
      >
         <picture class="rounded-inherit absolute inset-0 -z-1 pointer-events-none <tablet:bottom-68px <tablet:rounded-b-none">
            <source media="(max-width: 767px)" srcset="/img/banners/gl1m.jpg 1x, /img/banners/gl1m@2.jpg 2x" />
            <img
               srcset="/img/banners/gl1@2.jpg 2x"
               src="/img/banners/gl1.jpg"
               alt="gl1"
               class="rounded-inherit w-full h-full object-cover"
            />
         </picture>

         <h1 class="h2-semibold mb-16px <tablet:h3-semibold whitespace-pre-line">
            <span v-if="locale !== 'ru'" class="text-yellow-700">{{ userCount.toLocaleString() }}</span>
            {{ $t('9Yf1RA6ndCstKe-5fSLpF') }}
            <span v-if="locale === 'ru'" class="text-yellow-700">{{ userCount.toLocaleString() }}</span> {{ $t('d5Qf2sHf_ZNcQD0UodyWF') }}
         </h1>
         <span class="m-medium <tablethd:s-medium2 <tablet:flex-auto max-w-400px">
            <span class="text-yellow-700">DataLouna</span> {{ $t('9SmwQEbqmd4c9Q6Pagfu0') }}
         </span>
         <Button class="min-w-240px m-semibold py-16px px-24px mt-24px <tablet:px-6px <tablet:w-full" @click="buttonAccess(2)">
            {{ $t('9bviV5i_rFYBpwUCAR5gO') }}
         </Button>
      </section>
      <section
         class="flex flex-col items-start justify-center rounded-inherit absolute inset-0 pl-48px duration-500 <tablet:px-12px <tablet:pt-16px <tablet:pb-8px"
         :class="activeSlide === 3 ? 'opacity-100' : 'opacity-0 invisible'"
      >
         <picture
            v-if="locale === 'ru'"
            class="rounded-inherit absolute inset-0 -z-1 pointer-events-none <tablet:bottom-68px <tablet:rounded-b-none"
         >
            <source media="(max-width: 767px)" srcset="/img/banners/gl2mru.jpg 1x, /img/banners/gl2mru@2.jpg 2x" />
            <img
               srcset="/img/banners/gl2ru@2.jpg 2x"
               src="/img/banners/gl2ru.jpg"
               alt="gl1"
               class="rounded-inherit w-full h-full object-cover"
            />
         </picture>
         <picture v-else class="rounded-inherit absolute inset-0 -z-1 pointer-events-none <tablet:bottom-68px <tablet:rounded-b-none">
            <source media="(max-width: 767px)" srcset="/img/banners/gl2men.jpg 1x, /img/banners/gl2men@2.jpg 2x" />
            <img
               srcset="/img/banners/gl2en@2.jpg 2x"
               src="/img/banners/gl2en.jpg"
               alt="gl1"
               class="rounded-inherit w-full h-full object-cover"
            />
         </picture>

         <h1 class="h2-semibold mb-16px <tablet:h3-semibold">
            <span class="text-yellow-700">{{ $t('buAjGDHyl-t95v6_3edr4') }}</span>
            <br />
            {{ $t('0p5LpmNPwfFfr_Nbv4BRl') }}
         </h1>
         <div class="m-medium <tablethd:s-medium2 <tablet:flex-auto">
            <p>{{ $t('wIOvh-GF5rkts7Te8xJhA') }}</p>
            <p>{{ $t('1YI_fMnl0XusJ4d12rX4B') }}</p>
         </div>

         <Button class="min-w-240px m-semibold py-16px px-24px mt-24px <tablet:px-6px <tablet:w-full" @click="buttonAccess(3)">
            {{ $t('dashboard.get_started.Registerred.step2.button') }}
         </Button>
      </section>
      <section
         class="flex flex-col items-start justify-center rounded-inherit absolute inset-0 pl-48px duration-500 <tablet:px-12px <tablet:pt-16px <tablet:pb-8px"
         :class="activeSlide === 4 ? 'opacity-100' : 'opacity-0 invisible'"
      >
         <picture class="rounded-inherit absolute inset-0 -z-1 pointer-events-none <tablet:bottom-68px <tablet:rounded-b-none">
            <source media="(max-width: 767px)" srcset="/img/banners/gl3m.jpg 1x, /img/banners/gl3m@2.jpg 2x" />
            <img
               srcset="/img/banners/gl3@2.jpg 2x"
               src="/img/banners/gl3.jpg"
               alt="gl1"
               class="rounded-inherit w-full h-full object-cover"
            />
         </picture>

         <h1 class="h2-semibold mb-16px <tablet:h3-semibold">
            {{ $t('-OjTFgYeFMJ_Z5YAOUZ-U') }}
            <br />
            <span class="text-yellow-700">{{ $t('F9mYqk7ry0bhfyMsHigyM') }}</span>
         </h1>
         <div class="m-medium <tablethd:s-medium2 <tablet:flex-auto">{{ $t('tyPq9rKa80rOpNoa828RL') }}</div>

         <Button class="min-w-240px m-semibold py-16px px-24px mt-24px <tablet:px-6px <tablet:w-full" @click="buttonAccess(4)">
            {{ $t('subscription.profileWidget.subscriptionWidget.button.state1') }}
         </Button>
      </section>

      <SwiperControls
         :count="maxCountSlide"
         :active-count="activeSlide"
         class="absolute bottom-20px left-48px <tablet:-bottom-24px <tablet:left-1/2 <tablet:-translate-x-1/2"
         @selected="selectedSlide"
         @left-click="nextSlide"
         @right-click="prevSlide"
      />
   </article>
</template>

<style scoped>
.banner-luna-bg {
   background-image: url('/img/banners/luna-bg-full.png');
   background-size: auto 100%;
   background-position: right;
   background-repeat: no-repeat;
   z-index: 1;
}
.banner-luna-bg::before,
.banner-luna-bg::after {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;

   border-radius: 10px;
   z-index: -1;
}
.banner-luna-bg::before {
   background: radial-gradient(52.84% 100.1% at 0.85% 29.95%, rgba(71, 73, 94, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
}
.banner-luna-bg::after {
   background: radial-gradient(58.54% 223.7% at 102.86% 107.05%, rgba(222, 58, 30, 0.1) 1.67%, rgba(30, 31, 37, 0) 100%);
}

@media only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
   .banner-luna-bg {
      background-image: url('/img/banners/luna-bg-full@2.png');
   }
}

@screen lt-tablethd {
   .banner-luna-bg {
      background-position: calc(100% + 200px) 0;
   }
}

@screen lt-tablet {
   .banner-luna-bg {
      background-image: url('/img/banners/luna-bg-small.png');
      background-size: contain;
      background-position: center calc(100% - 68px);
   }

   .banner-luna-bg::before,
   .banner-luna-bg::after {
      bottom: 68px;
      border-radius: 10px 10px 0 0;
   }

   @media only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .banner-luna-bg {
         background-image: url('/img/banners/luna-bg-small@2.png');
      }
   }
}
</style>
