<script setup lang="ts">
import { i18nRoute } from '@/i18n'
import * as amplitude from '@/composables/amplitude'

const handler = () => {
   amplitude.track('click.try-free-ai', '5steps')
}
</script>

<template>
   <div class="flex <tablethd:flex-col <tablethd:items-center gap-x-80px pl-100px <fullhd:px-36px <tablethd:px-4px">
      <div class="flex-shrink-0 flex flex-col gap-y-44px items-center <tablethd:contents">
         <div class="relative -z-1">
            <div class="w-480px h-183px <tablet:w-320px <tablet:h-160px">
               <picture>
                  <source
                     media="(max-width: 767px)"
                     srcset="/img/what-you-get/match-info-bg-mobile.png 1x, /img/what-you-get/match-info-bg-mobile@2.png 2x"
                  />
                  <img srcset="/img/what-you-get/match-info-bg@2.png 2x" src="/img/what-you-get/match-info-bg.png" alt="match-info-bg" />
               </picture>
            </div>

            <div class="w-400px <tablet:w-304px absolute -bottom-28px <tablet:-bottom-24px left-1/2 -translate-x-1/2">
               <picture>
                  <source
                     media="(max-width: 767px)"
                     srcset="/img/what-you-get/match-info-mobile.png 1x, /img/what-you-get/match-info-mobile@2.png 2x"
                  />
                  <img srcset="/img/what-you-get/match-info@2.png 2x" src="/img/what-you-get/match-info.png" alt="match-info" />
               </picture>

               <div class="absolute -top-26px left-98px <tablet:top-63px <tablet:left-63px flex <tablet:items-end gap-x-8px">
                  <div class="flex flex-col items-end gap-4px">
                     <div class="w-24px aspect-square rounded-full bg-gray-50/4 border border-gray-50/2 backdrop-blur-25 flexc relative">
                        <div class="w-8px aspect-square rounded-full bg-yellow-700"></div>
                        <div class="note-glow"></div>
                     </div>
                     <div
                        class="w-13px h-66px <tablet:h-90px border-l tablet:border-b border-yellow-700 tablet:rounded-bl-6px <tablet:border-t <tablet:rounded-tl-6px <tablet:order-first"
                     ></div>
                  </div>

                  <div class="my-3px l-medium <tablet:m-medium">{{ $t('JNy3iYugU_NRdFcKzml7h') }}</div>
               </div>
            </div>
         </div>
         <router-link
            :to="i18nRoute({ name: 'signin' })"
            class="w-400px <tablethd:w-304px py-16px btn-lava-700 <tablethd:order-last text-center"
            @click="handler"
         >
            {{ $t('subscription.profileWidget.subscriptionWidget.button.state1') }}
         </router-link>
      </div>
      <div class="<tablethd:mt-88px <tablethd:mb-24px <tablethd:px-8px">
         <header class="relative flex items-center gap-x-16px mb-24px <tablethd:hidden">
            <div class="w-2px h-22px rounded-10px bg-yellow-700 shadow-yellow"></div>
            <h2>{{ $t('xRvTZe5iNYjhHxg8UQ_pY') }}</h2>
         </header>
         <div class="l-medium tablethd:ml-18px <tablet:m-medium">
            {{ $t('waY0Gb3AudrYIq6ruhijI') }} <span class="text-yellow-700">DataLouna:</span>
            <ul class="m-semibold <tablet:s-medium2 mt-16px mb-24px <tablethd:my-20px flex flex-col gap-y-4px">
               <li class="flex items-center gap-x-12px">
                  <div class="flex-shrink-0 w-36px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25">
                     <div class="drop-shadow-yellow"><i class="i-custom-analysis-outline text-20px text-yellow-700 block"></i></div>
                  </div>
                  {{ $t('fumTufwvr2V_bSQXJgDb5') }}
               </li>
               <li class="flex items-center gap-x-12px">
                  <div class="flex-shrink-0 w-36px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25">
                     <div class="drop-shadow-yellow"><i class="i-custom-speedometer-outline text-20px text-yellow-700 block"></i></div>
                  </div>
                  {{ $t('K-273fEuhw3j5EkNX-gSt') }}
               </li>
               <li class="flex items-center gap-x-12px">
                  <div class="flex-shrink-0 w-36px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25">
                     <div class="drop-shadow-yellow"><i class="i-custom-scales-outline text-20px text-yellow-700 block"></i></div>
                  </div>
                  {{ $t('SqkvGHbWUtcxuazrGpitW') }}
               </li>
            </ul>
            {{ $t('KVHJmE-KNK8QuF4amnZko') }} <span class="text-yellow-700">{{ $t('NhtOU15A2HMLpVleV-uga') }}</span>
            {{ $t('T5ON-VSEr0XPVMlCb3bEd') }} <span class="text-yellow-700">{{ $t('3CZR7nLhoG8N2q1dbxk01') }}</span>
            {{ $t('SNy_iIm3QSO86d-yyhPVT') }}
            <br />
            {{ $t('fINJB7JCTyHsmlWFfMe2f') }} <span class="text-yellow-700">{{ $t('JfVoL0wQVxMJIxjQAzWJT') }}</span>
         </div>
      </div>
   </div>
</template>

<style scoped>
.note-glow {
   position: absolute;
   inset: 0;
   background: radial-gradient(50% 20% at 50% 100%, rgba(241, 185, 10, 0.3) 0%, rgba(29, 30, 36, 0) 100%);
   border-radius: 100px;
}
</style>
