function parseCookie() {
   try {
      if (document === undefined || !document.cookie) {
         return {}
      }

      return document.cookie
         .split(';')
         .map((v) => v.split('='))
         .reduce(
            (accumulator, v) => {
               accumulator[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
               return accumulator
            },
            {} as Record<string, string>
         )
   } catch (error) {
      console.log('Error parsing cookie', error)

      return {}
   }
}

export function getCookie(name: string) {
   return parseCookie()[name]
}

export function getAllCookies() {
   return parseCookie()
}
