<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
   speed: number
   color: string
   width: number
   percent: number
}>()

const radius = computed(() => props.width * 0.46)
const dashArray = computed(() => 2 * Math.PI * radius.value)
const percentCalculated = computed(() => (dashArray.value * 0.5 * props.percent) / 100 - 9)
</script>

<template>
   <circle
      :cx="width * 0.5"
      :cy="width * 0.5"
      :r="radius"
      fill="none"
      :stroke="color"
      :stroke-dasharray="`0, ${dashArray}`"
      stroke-dashoffset="0"
   >
      <animate
         attributeName="stroke-dasharray"
         :dur="speed"
         :values="`0, ${dashArray}; ${percentCalculated}, ${dashArray}`"
         fill="freeze"
      />
   </circle>
</template>
