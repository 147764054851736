<script setup lang="ts">
import { ref, computed } from 'vue'
import SwiperControls from '@/components/SwiperControls.vue'

const props = defineProps<{
   title: string
   total: number
   column: number
}>()

const swiperCount = computed(() => {
   let result = Math.floor(props.total / props.column)
   if (props.total % props.column) result++
   return result
})
const swiperActive = ref(1)
const swiperLeftClick = () => {
   if (swiperActive.value > 1) swiperActive.value--
}
const swiperRightClick = () => {
   if (swiperActive.value < swiperCount.value) swiperActive.value++
}
const swiperSelected = (index: number) => {
   swiperActive.value = index
}
</script>

<template>
   <article class="flex flex-col gap-y-20px overflow-hidden">
      <header class="h-40px bg-black-850 rounded-10px px-24px <fullhd:px-20px <laptop:mx-16px flex items-center justify-between">
         <div class="flex items-center gap-x-8px <laptop:justify-between <laptop:flex-auto">
            <h2 class="h3-semibold">{{ title }}</h2>
            <slot name="header" />
         </div>

         <SwiperControls
            :count="swiperCount"
            :active-count="swiperActive"
            class="<laptop:hidden"
            @left-click="swiperLeftClick"
            @right-click="swiperRightClick"
            @selected="swiperSelected"
         />
      </header>
      <section
         class="flex gap-x-24px <laptop:gap-x-16px transition-transform duration-1000 <laptop:overflow-x-auto no-scrollbar <laptop:snap <laptop:snap-x <laptop:snap-mandatory <laptop:scroll-px-16px <laptop:px-16px"
         :style="`transform: translateX(calc(-${100 * (swiperActive - 1)}% - ${24 * (swiperActive - 1)}px))`"
      >
         <slot :total="total" />
      </section>
   </article>
</template>

<style scoped></style>
