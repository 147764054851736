<script setup lang="ts">
import { ref } from 'vue'
import type { FilterMatches } from '@/types/matches'
import * as amplitude from '@/composables/amplitude'

type Props = {
   count?: number
   modelValue?: boolean
   buttonFilter?: boolean
   filter?: FilterMatches
}

withDefaults(defineProps<Props>(), {
   modelValue: undefined,
   count: undefined,
   buttonFilter: true,
   filter: 'all'
})

const emits = defineEmits<{
   (event: 'update:modelValue', value: boolean): void
   (event: 'update:filter', status: FilterMatches): void
}>()

const changeTab = (status: FilterMatches) => {
   emits('update:filter', status)
   amplitude.track('click', 'filter-matches', status)
}

const mobileFilterIsActive = ref(false)
</script>

<template>
   <header
      class="bg-black-850 rounded-10px flex items-center <laptop:justify-between gap-x-20px <laptop:gap-x-4px px-24px <laptop:px-0 <laptop:flex-wrap"
      :class="mobileFilterIsActive ? 'h-76px' : 'h-40px'"
   >
      <div class="h-40px flex-auto flex items-center gap-x-8px <laptop:pl-12px <laptop:overflow-hidden <laptop:max-w-246px">
         <h2 class="h3-semibold <laptop:truncate <tablet:l-medium"><slot /></h2>
         <div v-if="count !== undefined" class="flex-shrink-0 w-34px h-20px rounded-full bg-black-900 flexc m-medium text-gray-800 pt-1px">
            {{ count }}
         </div>
      </div>

      <div
         v-if="buttonFilter"
         class="laptop:h-full flex m-medium <laptop:s-medium2 <laptop:gap-x-16px <laptop:order-last text-gray-800 <laptop:relative <laptop:flex-auto <laptop:pt-9px <laptop:justify-center"
         :class="{ '<laptop:hidden': !mobileFilterIsActive }"
      >
         <div class="hidden <laptop:block h-1px bg-black-700 absolute top-0 left-0 right-0"></div>
         <button
            class="px-20px <laptop:px-0 <laptop:pb-8px <laptop:border-b-2 <laptop:border-transparent"
            :class="
               filter === 'all'
                  ? 'bg-active-tab text-gray-50 <laptop:border-yellow-700'
                  : '&hover:text-orange-900 transition-colors duration-200'
            "
            @click="changeTab('all')"
         >
            {{ $t('matches.live.filters.item1') }}
            <span class="<laptop:hidden lowercase">{{ $t('dashboard.sidebar_timetable.matches') }}</span>
         </button>
         <button
            class="px-20px <laptop:px-0 <laptop:pb-8px <laptop:border-b-2 <laptop:border-transparent"
            :class="
               filter === 'recommended'
                  ? 'bg-active-tab text-gray-50 <laptop:border-yellow-700'
                  : '&hover:text-orange-900 transition-colors duration-200'
            "
            @click="changeTab('recommended')"
         >
            {{ $t('matches.upcomingMatches.filters.item2') }}
         </button>
         <button
            class="px-20px <laptop:px-0 <laptop:pb-8px flex items-center gap-x-8px <laptop:gap-x-6px <laptop:border-b-2 <laptop:border-transparent"
            :class="
               filter === 'risky'
                  ? 'bg-active-tab text-gray-50 <laptop:border-yellow-700'
                  : '&hover:text-orange-900 transition-colors duration-200'
            "
            @click="changeTab('risky')"
         >
            <span>{{ $t('matches.upcomingMatches.filters.item3') }}</span>

            <i v-tippy="$t('dashboard.matchCard.riskyHover')" class="i-custom-info-outline text-16px <laptop:text-12px block"></i>
         </button>
      </div>

      <div class="flex items-center gap-x-12px <laptop:pr-12px">
         <button v-if="buttonFilter" class="relative laptop:hidden" @click="mobileFilterIsActive = !mobileFilterIsActive">
            <i
               class="block text-16px"
               :class="mobileFilterIsActive ? 'text-orange-900 i-custom-filter-solid' : 'i-custom-filter-outline'"
            ></i>
            <div v-if="filter !== 'all'" class="w-6px aspect-square rounded-full bg-orange-900 absolute -top-3px -right-3px"></div>
         </button>

         <button
            v-if="modelValue !== undefined"
            class="border border-black-700 rounded-full w-38px h-20px flexc &hover:border-orange-900 group transition-border duration-200"
            @click="emits('update:modelValue', !modelValue)"
         >
            <div
               class="i-custom-arrow-outline text-14px &group-hover:text-orange-900 transition-colors duration-200"
               :class="modelValue ? 'rotate-180' : ''"
            ></div>
         </button>

         <slot name="right" />
      </div>
   </header>
</template>

<style scoped>
@media (min-width: 1440px) {
   .bg-active-tab {
      background-image: var(--gradient-selected-800);
   }
}
</style>
