<script setup lang="ts">
type Props = {
   count: number
   activeCount: number
}

withDefaults(defineProps<Props>(), {
   activeCount: 1
})

const emits = defineEmits<{
   (event: 'leftClick'): void
   (event: 'rightClick'): void
   (event: 'selected', value: number): void
}>()
</script>

<template>
   <div class="h-16px flex items-center gap-x-12px text-12px text-yellow-700">
      <button class="h-full aspect-square group" @click="emits('leftClick')">
         <div class="i-custom-arrow-outline rotate-90 &group-hover:text-orange-900 trs"></div>
      </button>
      <button v-for="i in count" :key="i" class="h-full group" @click="emits('selected', i)">
         <div
            class="w-24px h-4px rounded-full border"
            :class="
               i === activeCount
                  ? 'bg-yellow-700 border-transparent'
                  : ' border-black-700 &group-hover:border-orange-900 trs'
            "
         ></div>
      </button>

      <button class="h-full aspect-square group" @click="emits('rightClick')">
         <div class="i-custom-arrow-outline rotate-270 &group-hover:text-orange-900 trs"></div>
      </button>
   </div>
</template>

<style scoped></style>
