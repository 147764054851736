<script setup lang="ts">
import { useCurrentEvent } from '@/composables'
import { useRouter } from 'vue-router'
import { i18nRoute } from '@/i18n'
import { useUserStore } from '@/stores/user'
import { useAddCard } from '@/composables/add-card'
import * as amplitude from '@/composables/amplitude'

const router = useRouter()
const userStore = useUserStore()

const { addCardMutation } = useAddCard()

const { event, eventIsLive } = useCurrentEvent()

const buttonAccess = () => {
   amplitude.track('click.free-trial', 'fair-trial')

   if (userStore.userUnReg) {
      return router.push(i18nRoute({ name: 'signout', query: { next: 'sub' } }))
   }

   if (userStore.userNew) {
      addCardMutation()
   }
}
</script>

<template>
   <section
      class="overflow-hidden fair-trial-bg tablethd:h-156px rounded-10px flex tablethd:items-center <tablethd:flex-col px-24px <tablethd:pt-24px <tablethd:pb-12px <tablethd:px-12px relative z-1 <laptop:mx-16px"
   >
      <div class="flex tablethd:items-center gap-x-28px <tablethd:gap-y-16px <tablethd:flex-col">
         <div class="h2-semibold <tablethd:h3-semibold">
            <div>{{ $t('-cdAOGJvYMG8pYGIZX3T6') }}</div>
            <span class="text-yellow-700">{{ $t('J7P_Ee4Ad4hLzVijLnY_N') }}</span> {{ $t('2KZsPIj33yhHc4_Z2CMoL') }}
         </div>
         <div class="w-1px bg-gray-800 rounded-full self-stretch <tablethd:hidden"></div>
         <div class="flex flex-col gap-y-8px <tablethd:gap-y-12px m-medium <tablethd:s-medium2 whitespace-pre-line">
            <div class="flex items-center gap-x-12px">
               <div class="w-32px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25 relative">
                  <div class="absolute inset-0px fair-trial-icon-glow"></div>
                  <i class="i-custom-calendar-solid text-16px text-yellow-700"></i>
               </div>
               <div class="max-w-200px <tablethd:max-w-132px">
                  {{ $t('ZU_MY5TnoVMeEekxJsECH') }} <span class="text-yellow-700">{{ $t('qZXVbUVDszxZPBJxA1oZN') }}</span>
                  {{ $t('UwZ8I6-OC1PgTTcChfy43') }}
               </div>
            </div>
            <div class="flex items-center gap-x-12px">
               <div class="w-32px aspect-square rounded-10px bg-gray-50/4 border border-gray-50/2 flexc backdrop-blur-25 relative">
                  <div class="absolute inset-0px fair-trial-icon-glow"></div>
                  <i class="i-custom-snowflake-solid text-16px text-yellow-700"></i>
               </div>
               <div class="max-w-200px <tablethd:max-w-132px">
                  {{ $t('9MP3YnpI0qzlkLewbZGNh') }}
                  <span class="text-yellow-700">{{ $t('Rh_kZPlQ_0ZgqPINdaEcf') }} </span>{{ $t('KquEzU-87GH9mf4VB4v5T') }}
               </div>
            </div>
         </div>
      </div>

      <div class="tablethd:absolute right-24px bottom-12px">
         <button
            class="btn-lava w-256px p-12px m-semibold rounded-10px mb-12px <tablethd:mt-26px <tablethd:mb-12px <tablethd:w-full"
            @click="buttonAccess"
         >
            {{ $t('7NOU8qmBf4FKQwQ0MX6n_') }}
         </button>

         <div class="flex flex-col items-end gap-y-2px <tablethd:items-center">
            <span class="xs-medium text-yellow-700">{{ eventIsLive ? $t('3FnrvZ-DtZ8uCajXVmSqA') : $t('hFb_8Cc4IYLrHSFfq5NR-') }}</span>
            <div class="flex items-center gap-x-8px s-semibold max-w-full">
               <img v-if="event?.logo" :src="event?.logo" alt="event logo" class="w-20px aspect-square" />
               <div v-else class="relative">
                  <i class="i-custom-tournament-outline text-20px block text-black-800"></i>
                  <div class="absolute inset-0 flex justify-center text-9px mt-2.5px">?</div>
               </div>

               <div class="truncate">{{ event?.name || $t('lbfrIfSjwW-lSxK7EAn5C') }}</div>
            </div>
         </div>
      </div>
      <picture>
         <source media="(max-width: 1023px)" srcset="/img/fair-trial/bg-m.png 1x, /img/fair-trial/bg-m@2.png 2x" />
         <img
            srcset="/img/fair-trial/bg@2.png 2x"
            src="/img/fair-trial/bg.png"
            alt="fair trial bg"
            class="absolute top-0 right-100px <fullhd:right-0 <tablethd:right-0 -z-1 <tablethd:h-248px"
         />
      </picture>
   </section>
</template>

<style scoped>
.fair-trial-bg {
   background: radial-gradient(98.31% 106.14% at 0% 100%, rgba(71, 73, 94, 0.2) 0%, rgba(29, 30, 36, 0) 100%), #1d1e24;
}
.fair-trial-icon-glow {
   background: radial-gradient(50% 51.14% at 50% 100%, rgba(241, 185, 10, 0.1) 0%, rgba(29, 30, 36, 0) 100%);
   border-radius: inherit;
}
</style>
