<script setup lang="ts">
import { computed } from 'vue'
import { locale } from '@/i18n'
import SwiperBlockContent from '@/components/SwiperBlockContent.vue'

defineProps<{
   column: number
}>()

const list = computed(() => {
   if (locale.value === 'ru') {
      return [
         {
            link: 'https://www.youtube.com/embed/sfWOpk43zB8',
            name: 'Иван',
            age: '18 лет',
            city: 'Саратов',
            profession: 'Студент'
         },
         {
            link: 'https://www.youtube.com/embed/teOJFrSBhus',
            name: 'Тигран',
            age: '19 лет',
            city: 'Самара',
            profession: 'SMM'
         },
         {
            link: 'https://www.youtube.com/embed/sV1pcuQQ0EM',
            name: 'Полина',
            age: '28 лет',
            city: 'Москва',
            profession: 'SMM'
         },
         {
            link: 'https://www.youtube.com/embed/4Svi72NeNyQ',
            name: 'Тимур',
            age: '35 лет',
            city: 'Екатеринбург',
            profession: 'Инженер'
         },
         {
            link: 'https://www.youtube.com/embed/_mVf4RDIS2c',
            name: 'Элина',
            age: '27 лет',
            city: 'Москва',
            profession: 'Бизнес-Ассистент'
         },
         {
            link: 'https://www.youtube.com/embed/xbhUi5MD1xc',
            name: 'Сергей',
            age: '32 года',
            city: 'Санкт-Петербург',
            profession: 'Охранник'
         }
      ]
   } else {
      return [
         {
            link: 'https://www.youtube.com/embed/ddAKCGXvI8c',
            name: 'Levan',
            age: '28 years',
            city: 'Georgia',
            profession: 'Affiliate marketing'
         },
         {
            link: 'https://www.youtube.com/embed/7JOrrLQ3P3M',
            name: 'Elina',
            age: '27 years',
            city: 'Moscow',
            profession: 'Business assistant'
         },
         {
            link: 'https://www.youtube.com/embed/hZBrLVCrQJY',
            name: 'Timur',
            age: '35 years',
            city: 'Ekaterinburg',
            profession: 'Engineer'
         },
         {
            link: 'https://www.youtube.com/embed/LFZXGvi3yQQ',
            name: 'Tigran',
            age: '19 years',
            city: 'Samara',
            profession: 'SMM'
         },
         {
            link: 'https://www.youtube.com/embed/BKmmFOIdkn4',
            name: 'Ivan',
            age: '18 years',
            city: 'Saratov',
            profession: 'Student'
         },
         {
            link: 'https://www.youtube.com/embed/D96en-ZsRiE',
            name: 'Polina',
            age: '28 years',
            city: 'Moscow',
            profession: 'SMM'
         }
      ]
   }
})
</script>

<template>
   <SwiperBlockContent :column="column" :title="$t('about.testimonials.title')" :total="6">
      <div
         v-for="(review, index) in list"
         :key="index"
         class="flex-shrink-0 <laptop:w-292px! h-519px flex-shrink-0 rounded-10px overflow-hidden relative flex flex-col justify-end p-4px <laptop:snap-start"
         :style="`width: calc(${100 / column}% - 18px)`"
      >
         <iframe
            height="519"
            :src="review.link"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            class="absolute inset-0 w-full"
            loading="lazy"
         ></iframe>

         <div class="h-54px rounded-10px bg-black-850/20 shadow-black flex flex-col items-center justify-center relative z-1">
            <div class="pb-8px s-medium">{{ review.name }}</div>
            <div class="xs-medium">{{ review.age }} • {{ review.city }} • {{ review.profession }}</div>

            <div class="absolute inset-0 bg-gray-50/4 border-2 border-gray-50/2 backdrop-blur-25 rounded-10px -z-1"></div>
         </div>
      </div>
   </SwiperBlockContent>
</template>

<style scoped></style>
