<script setup lang="ts">
import { computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { getFutureAndLiveMatches } from '@/gql/requests'
import { useUserStore } from '@/stores/user'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import { locale, i18nRoute } from '@/i18n'
import Team from '@/components/Match/Team.vue'
import Button from '@/components/UI/Button.vue'
import * as amplitude from '@/composables/amplitude'

const router = useRouter()
const userStore = useUserStore()

const { result } = useQuery(
   getFutureAndLiveMatches,
   {},
   {
      fetchPolicy: 'cache-only'
   }
)

const match = computed(() => result.value?.matches.find((match) => match.isPromo))

const dateDay = computed(() => dayjs(match.value?.date).locale(locale.value).format('D MMMM'))
const dateTime = computed(() => dayjs(match.value?.date).locale(locale.value).format('HH:mm'))

const coefficientDown = computed(
   () => (match.value?.odds && match.value.odds.matchOutcome.team1 < match.value.odds.matchOutcome.team2) ?? false
)

const buttonAccess = () => {
   amplitude.track('click', 'promo-banner')
   router.push(i18nRoute({ name: 'signin' }))
}
</script>

<template>
   <article v-if="userStore.userUnReg && match" class="bg-black-850 relative promo-banner">
      <header class="text-center pt-12px <laptop:pt-16px min-h-73px <laptop:min-h-52px">
         <h3 class="<laptop:l-medium">
            {{ $t('r2iwJT1hv_KKI6XpnhACk') }} <br class="<laptop:hidden" />
            {{ $t('NKS86iONb0fNX86_xeuZr') }}
         </h3>
      </header>

      <div class="match">
         <div class="absolute top-6px left-8px right-8px grid grid-cols-[1fr_auto_1fr] items-top">
            <div>
               <div
                  v-if="!match.recommended"
                  v-tippy="$t('lZU8AcooYylj63nbI7BM-')"
                  class="i-custom-risk-solid text-red-700 text-16px"
               ></div>
            </div>

            <div v-if="match.status === 'LIVE'" class="h-16px px-12px flex items-center gap-x-4px border border-red-700/30 rounded-10px">
               <div class="w-4px h-4px rounded-full bg-red-700"></div>
               <span class="s-semibold text-red-700">Live</span>
            </div>
            <div v-else class="w-144px py-3px flex items-center justify-center gap-x-8px s-semibold border border-brown-700 rounded-full">
               <span>{{ dateDay }}</span>
               <div class="w-1px h-12px bg-brown-700 rounded-full"></div>
               <span>{{ dateTime }}</span>
            </div>

            <div class="justify-self-end">
               <div
                  v-if="Boolean(match.comment?.all) || Boolean(match.comment?.team1) || Boolean(match.comment?.team2)"
                  class="i-custom-comment-solid text-gray-800 text-16px"
               ></div>
            </div>
         </div>

         <Team
            :name="match.team1.name"
            :logo="match.team1.logo"
            :logo-x1="match.team1.logo_x1"
            :logo-x2="match.team1.logo_x2"
            :coefficient="match.odds?.matchOutcome.team1 ?? 0"
            class="py-12px"
            :coefficient-down="coefficientDown"
         />

         <div class="flex flex-col items-center gap-y-2px">
            <div class="w-1px h-10px rounded-full bg-brown-700"></div>
            <span class="m-semibold text-brown-700">vs</span>
            <div class="w-1px h-65px rounded-full bg-brown-700"></div>
         </div>

         <Team
            :name="match.team2.name"
            :logo="match.team2.logo"
            :logo-x1="match.team2.logo_x1"
            :logo-x2="match.team2.logo_x2"
            :coefficient="match.odds?.matchOutcome.team2 ?? 0"
            :coefficient-down="!coefficientDown"
            class="py-12px"
         />

         <div class="glow"></div>
      </div>

      <div class="relative z-1 flex items-center justify-center gap-x-12px py-20px <laptop:py-12px m-semibold <laptop:s-semibold">
         <div class="flex items-center gap-x-8px">
            <div class="w-20px h-20px flexc">
               <img :src="match.event.logo ?? '#'" alt="Event logo" />
            </div>
            <span class="uppercase max-w-160px truncate">{{ match.event.name }}</span>
         </div>
         <div class="w-1px h-12px bg-black-700 rounded-full"></div>
         <span class="uppercase">BO{{ match.bestOf }}</span>
      </div>

      <div class="px-24px mb-24px <laptop:mb-12px <laptop:px-12px">
         <Button class="py-12px m-semibold w-full" @click="buttonAccess">{{ $t('UqQRQ-HNemNtBxkfE7-LB') }}</Button>
      </div>
   </article>
</template>

<style scoped>
.promo-banner {
   background-image: url('/img/promo/header.jpg');
   background-size: 100% 73px;
   background-position: top;
   background-repeat: no-repeat;
   overflow: hidden;
   z-index: 1;
}
.promo-banner::before {
   content: '';
   position: absolute;
   inset: 0;
   border-radius: 10px;
   padding: 2px;
   z-index: 2;
   background-image: var(--gradient-lava-700);
   -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
   -webkit-mask-composite: xor;
   mask-composite: exclude;
   pointer-events: none;
}

@media only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
   .banner-luna-bg {
      background-image: url('/img/promo/header@2.jpg');
   }
}

@screen lt-laptop {
   .promo-banner {
      background-size: 100% 52px;
   }
}

.match {
   --uno: 'grid grid-cols-[1fr_20px_1fr] gap-x-14px justify-between items-end min-h-132px';
   --uno: 'rounded-10px px-12px relative z-1 <fullhd:px-8px';
}
.match::before,
.match::after {
   content: '';
   --uno: 'absolute inset-0 bg-no-repeat bg-contain -z-1';
}
.match::before {
   background-image: url('/img/match/bg-left.svg');
   --uno: 'bg-left';
}
.match::after {
   background-image: url('/img/match/bg-right.svg');
   --uno: 'bg-right';
}

.glow {
   --uno: 'absolute inset-0 -z-1 rounded-10px';
   background-image: radial-gradient(41.47% 43.75% at 49.84% 100%, rgba(222, 58, 30, 0.07) 0%, rgba(29, 30, 36, 0) 100%);
}
.glow::after {
   content: '';
   --uno: 'absolute inset-0 -z-1 rounded-10px';
   background: radial-gradient(190.97% 103.95% at 112.99% 135.13%, rgba(222, 58, 30, 0.07) 0%, rgba(30, 31, 37, 0) 100%);
}
</style>
