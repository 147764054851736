import * as amplitude from '@amplitude/analytics-browser'
import { useUserStore } from '@/stores/user'
import { getCookie } from '@/composables/cookie'

export async function init() {
   amplitude.init('6051c13985f119feb758e8287be6c1c1', {
      defaultTracking: true,
      serverUrl: '/amplitude'
   })
}

export function track(eventName: string, eventCategory: string, value?: number | string | boolean) {
   const userStore = useUserStore()
   const payload: {
      user_id?: string
      user_properties?: {
         plan: string
         email: string
         fbp?: string
         userAgent?: string
      }
   } = {}

   if (userStore.user) {
      payload.user_id = `user-${userStore.user.id}`
      payload.user_properties = {
         plan: userStore.user.subscription.plan ?? 'base',
         email: userStore.user.email
      }

      const fbp = getCookie('_fbp')

      if (fbp) {
         payload.user_properties.fbp = fbp
      }

      const userAgent = window?.navigator?.userAgent
      if (userAgent) {
         payload.user_properties.userAgent = userAgent
      }
   }

   amplitude.track({
      event_type: eventName,
      event_properties: {
         category: eventCategory,
         value
      },
      ...payload
   })
}
