<script setup lang="ts">
import { ref, watch } from 'vue'
import { useUserStore } from '@/stores'
import { useQuery } from '@vue/apollo-composable'
import { getBestMatches } from '@/gql/requests'
import BestMatch from '@/components/Match/Best.vue'
import MatchesHeader from '@/components/Match/MatchesHeader.vue'

const userStore = useUserStore()

const activeHeader = ref(true)

const { result: matches } = useQuery(getBestMatches)

watch(
   () => userStore.loading,
   (nv) => {
      if (!nv && userStore.userSub) {
         activeHeader.value = false
      }
   },
   { immediate: true }
)
</script>

<template>
   <section class="grid gap-y-20px">
      <MatchesHeader v-model="activeHeader" :button-filter="false">{{ $t('dashboard.bestMatches.title') }}</MatchesHeader>
      <div v-if="activeHeader" class="grid grid-cols-3 <laptop:grid-cols-1 gap-20px <laptop:gap-16px">
         <BestMatch v-for="match in matches?.bestMatches" :key="match.id" :match="match" />
      </div>
   </section>
</template>

<style scoped></style>
