<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import { useUserStore } from '@/stores/user'
import { locale, i18nRoute } from '@/i18n'
import { chanceSucceeded, team1HasMoreChance } from '@/composables/matches'
import Team from '@/components/Match/Team.vue'
import Spinner from '@/components/UI/Spinner.vue'
import type { TeamModel } from '@/gql/generate/graphql'
import { useAddCard } from '@/composables/add-card'
import * as amplitude from '@/composables/amplitude'

type Props = {
   id: number
   date: Date
   isLive: boolean
   team1: TeamModel
   team2: TeamModel
   odds: [number, number]
   chance?: [number, number]
   score?: [number, number]
   risky: boolean
   comment: boolean
   expectComment: boolean
   winnerId?: number
   bestMatch?: boolean
   finalChance?: boolean
   isPromo?: boolean
}

const userStore = useUserStore()
const router = useRouter()

const { addCardMutation } = useAddCard()

const props = withDefaults(defineProps<Props>(), {
   odds: () => [0, 0],
   chance: undefined,
   score: undefined,
   winnerId: undefined,
   risky: false,
   comment: false,
   expectComment: false,
   bestMatch: false,
   finalChance: false
})

const dateDay = computed(() => dayjs(props.date).locale(locale.value).format('D MMMM'))
const dateTime = computed(() => dayjs(props.date).locale(locale.value).format('HH:mm'))

const leftChanceLarge = computed(() => (props.chance ? props.chance[0] > props.chance[1] : undefined))

const winnerLeft = computed(() => props.winnerId === props.team1.id)

const coefficientDown = computed(() => props.odds && props.odds[0] < props.odds[1])

const handlerUnReg = () => {
   amplitude.track(`click.${props.isPromo ? 'promo' : 'get-7-days'}`, 'match')
}

const accessHandler = () => {
   amplitude.track(`click.${userStore.userNew ? 'get-7-days' : 'renewal'}`, 'match')
   if (userStore.userNew) {
      addCardMutation()
   } else {
      router.push(
         i18nRoute({ name: 'subscription', query: { tariff: userStore.user?.subscription.lastPaymentPlan?.toLowerCase() ?? 'pro' } })
      )
   }
}
</script>

<template>
   <router-link
      :to="i18nRoute({ name: 'match', params: { id } })"
      class="bg-black-850 rounded-10px relative group &hover:shadow-black2 transition-shadow duration-200"
   >
      <div class="match">
         <div class="absolute top-8px left-8px right-8px grid grid-cols-[1fr_auto_1fr] items-top">
            <div>
               <div v-if="risky" v-tippy="$t('lZU8AcooYylj63nbI7BM-')" class="i-custom-risk-solid text-red-700 text-16px"></div>
            </div>

            <div v-if="score" class="w-100px py-2px grid grid-cols-[1fr_auto_1fr] gap-x-4px bg-black-900 rounded-full m-semibold">
               <span class="justify-self-end">{{ score[0] }}</span>
               <span>:</span>
               <span>{{ score[1] }}</span>
            </div>
            <div v-else-if="isLive" class="h-16px px-12px flex items-center gap-x-4px border border-red-700/30 rounded-10px">
               <div class="w-4px h-4px rounded-full bg-red-700"></div>
               <span class="s-semibold text-red-700">Live</span>
            </div>
            <div v-else class="w-144px py-3px flex items-center justify-center gap-x-8px s-semibold border border-brown-700 rounded-full">
               <span>{{ dateDay }}</span>
               <div class="w-1px h-12px bg-brown-700 rounded-full"></div>
               <span>{{ dateTime }}</span>
            </div>
            <div class="justify-self-end">
               <div
                  v-if="comment"
                  v-tippy="$t('dashboard.matchCard.commentHover')"
                  class="flex-shrink-0 i-custom-comment-solid text-gray-800 text-16px block"
               ></div>
               <div
                  v-else-if="expectComment"
                  v-tippy="$t('ippblBzZu-veUpuF2AnEn')"
                  class="flex-shrink-0 i-custom-commentwaiting-solid text-gray-800 text-16px block"
               ></div>
            </div>
         </div>

         <Team
            :name="team1.name"
            :logo="team1.logo"
            :logo-x1="team1.logo_x1"
            :logo-x2="team1.logo_x2"
            :coefficient="odds[0]"
            class="py-12px"
            :coefficient-down="coefficientDown"
            :coefficient-green="bestMatch && leftChanceLarge && winnerLeft"
         />

         <div v-if="bestMatch && chance" class="flex items-start justify-between h3-semibold">
            <span class="mt-8px justify-self-end" :class="leftChanceLarge ? 'text-green-500' : 'text-red-700'">{{ chance[0] }}%</span>
            <div
               class="w-1px h-63px rounded-full"
               :class="[{ 'stick-bg': !winnerId }, { 'stick-bg-winner': !bestMatch && winnerId }]"
            ></div>
            <span class="mt-8px" :class="leftChanceLarge ? 'text-red-700' : 'text-green-500'">{{ chance[1] }}%</span>
         </div>
         <div v-else-if="chance && winnerId" class="flex items-start justify-between h3-semibold">
            <span class="mt-8px justify-self-end" :class="chanceSucceeded(chance, odds, team1.id, team2.id, winnerId, team1.id)"
               >{{ chance[0] }}%</span
            >
            <div
               class="w-1px h-63px rounded-full"
               :class="[{ 'stick-bg': !winnerId }, { 'stick-bg-winner': !bestMatch && winnerId }]"
            ></div>
            <span class="mt-8px" :class="chanceSucceeded(chance, odds, team1.id, team2.id, winnerId, team2.id)">{{ chance[1] }}%</span>
         </div>
         <div v-else-if="chance" class="flex items-start justify-between h3-semibold">
            <span
               class="mt-8px justify-self-end"
               :class="finalChance ? (team1HasMoreChance(chance, odds) ? 'text-green-500' : 'text-red-700') : ''"
               >{{ chance[0] }}%</span
            >
            <div class="w-1px h-63px rounded-full" :class="[{ 'stick-bg': !winnerId }]"></div>
            <span class="mt-8px" :class="finalChance ? (team1HasMoreChance(chance, odds) ? 'text-red-700' : 'text-green-500') : ''"
               >{{ chance[1] }}%</span
            >
         </div>
         <div
            v-else-if="(!chance && userStore.userSub) || (isPromo && userStore.user)"
            class="flex items-start justify-center gap-x-12px text-24px text-brown-600"
         >
            <tippy class="flex-shrink-0">
               <i class="i-custom-sandclock-solid block"></i>

               <template #content>
                  <div class="flex items-center gap-x-8px">
                     {{ $t('G1jU-ciN9p04gWH09ZUPu') }}
                     <Spinner class="w-1em h-1em" />
                  </div>
               </template>
            </tippy>

            <div class="w-1px h-63px rounded-full stick-bg"></div>

            <tippy class="flex-shrink-0">
               <i class="i-custom-sandclock-solid block"></i>

               <template #content>
                  <div class="flex items-center gap-x-8px">
                     {{ $t('G1jU-ciN9p04gWH09ZUPu') }}
                     <Spinner class="w-1em h-1em" />
                  </div>
               </template>
            </tippy>
         </div>
         <div v-else class="flex items-start justify-center gap-x-12px text-24px text-brown-600 &group-hover:text-orange-900 trs">
            <span class="i-custom-lock-solid mt-6px"></span>
            <div class="w-1px h-63px rounded-full stick-bg"></div>
            <span class="i-custom-lock-solid mt-6px"></span>
         </div>

         <Team
            :name="team2.name"
            :logo="team2.logo"
            :logo-x1="team2.logo_x1"
            :logo-x2="team2.logo_x2"
            :coefficient="odds[1]"
            :coefficient-down="!coefficientDown"
            class="py-12px"
            :coefficient-green="bestMatch && !leftChanceLarge && !winnerLeft"
         />

         <div
            v-if="winnerId"
            class="absolute top-0 bottom-0 w-1/2 -z-1 flex flex-col gap-y-6px justify-end"
            :class="[winnerLeft ? 'left-0 pl-12px rounded-l-10px' : 'right-0 pr-12px rounded-r-10px', { 'winner-bg': bestMatch }]"
         >
            <div class="flex items-center gap-x-6px text-green-500" :class="winnerLeft ? 'pr-12px flex-row-reverse' : 'pl-12px'">
               <span v-if="bestMatch" class="s-semibold">WIN</span>
               <span class="i-custom-crown-solid text-10px"></span>
            </div>
            <div class="w-full h-1px bg-green-500" :class="winnerLeft ? 'rounded-l-full' : 'rounded-r-full'"></div>
         </div>
         <div v-else class="glow"></div>
      </div>
      <div class="px-12px <fullhd:px-8px flex justify-center s-semibold relative">
         <slot />
      </div>
      <div
         class="absolute bottom-0 left-48px right-48px h-2px bg-orange-900 rounded-full opacity-0 invisible &group-hover:opacity-100 &group-hover:visible duration-300"
      ></div>

      <div
         v-if="!chance && userStore.userUnReg"
         class="absolute inset-0 bg-black-900/50 z-1 opacity-0 &group-hover:opacity-100 trs flex justify-center <laptop:hidden"
         :class="isLive ? 'items-start pt-95px' : 'items-center'"
      >
         <router-link
            :to="i18nRoute({ name: 'signout' })"
            class="btn-lava w-240px py-12px m-semibold rounded-10px text-center"
            @click.prevent="handlerUnReg"
         >
            {{ isPromo ? $t('UqQRQ-HNemNtBxkfE7-LB') : $t('vFQ8roqPpgSwLP-6IbXsm') }}
         </router-link>
      </div>
      <div
         v-else-if="!chance && userStore.user && !userStore.user.subscription.active && !isPromo"
         class="absolute inset-0 bg-black-900/50 z-1 opacity-0 &group-hover:opacity-100 trs flex items-center justify-center <laptop:hidden"
         :class="isLive ? 'items-start pt-95px' : 'items-center'"
      >
         <button class="btn-lava w-240px py-12px m-semibold rounded-10px text-center" @click="accessHandler">
            {{ userStore.userNew ? $t('vFQ8roqPpgSwLP-6IbXsm') : $t('QLTzbYlvxbNO7bsw3GSF-') }}
         </button>
      </div>
   </router-link>
</template>

<style scoped>
.match {
   --uno: 'grid grid-cols-[1fr_110px_1fr] gap-x-4px justify-between items-end';
   --uno: 'rounded-10px px-12px relative z-1 <fullhd:px-8px';
}
.match::before,
.match::after {
   content: '';
   --uno: 'absolute inset-0 bg-no-repeat bg-contain -z-1 rounded-10px';
}
.match::before {
   background-image: url('/img/match/bg-left.svg');
   --uno: 'bg-left';
}
.match::after {
   background-image: url('/img/match/bg-right.svg');
   --uno: 'bg-right';
}

.glow {
   --uno: 'absolute inset-0 -z-1 rounded-10px';
   background-image: radial-gradient(41.47% 43.75% at 49.84% 100%, rgba(222, 58, 30, 0.07) 0%, rgba(29, 30, 36, 0) 100%);
}
.glow::after {
   content: '';
   --uno: 'absolute inset-0 -z-1 rounded-10px';
   background: radial-gradient(190.97% 103.95% at 112.99% 135.13%, rgba(222, 58, 30, 0.07) 0%, rgba(30, 31, 37, 0) 100%);
}

.stick-bg {
   background-image: linear-gradient(180deg, #3c3232 0%, rgba(60, 50, 50, 0) 100%);
}
.stick-bg-winner {
   background: linear-gradient(180deg, #363843 0%, rgba(54, 56, 67, 0) 100%);
}
.winner-bg {
   background-image: linear-gradient(179.9deg, rgba(29, 30, 36, 0) 9.98%, rgba(44, 194, 86, 0.05) 99.92%);
}
</style>
