import { ref, computed } from 'vue'
import io from 'socket.io-client'

export const mapsCatalog = new Map([
   ['de_dust2', 'Dust2'],
   ['de_inferno', 'Inferno'],
   ['de_mirage', 'Mirage'],
   ['de_nuke', 'Nuke'],
   ['de_overpass', 'Overpass'],
   ['de_train', 'Train'],
   ['de_vertigo', 'Vertigo'],
   ['de_ancient', 'Ancient'],
   ['de_anubis', 'Anubis'],
   ['de_cache', 'Cache'],
   ['de_cbble', 'Cobblestone'],
   ['de_tuscan', 'Tuscan']
])

type MapName = string
type MatchId = number

export type Scores = {
   [teamId: number]: number
}

type HalfData = {
   ctTeamDbId: number
   ctScore: number
   tTeamDbId: number
   tScore: number
}

export type MapData = {
   firstHalf: HalfData
   secondHalf: HalfData
   overtime: HalfData
   mapOrdinal: number
   scores: Scores
   currentCtId: number
   currentTId: number
   defaultWin: boolean
   map: string
   mapOver: boolean
}

type MapScores = {
   [mapNumber: string]: MapData
}

type LiveLog = {
   [validateRequirement: string]: boolean
}

type SeriesRuntime = {
   mapScores: MapScores | Record<string, never>
   listId: number
   wins: Scores | Record<string, never>
   liveLog: LiveLog | Record<string, never>
   forcedLive: boolean
   forcedDead: boolean
}

export type MatchMapsScores = Map<MapName, MapData>
export const matchesMapsScores = ref<Map<MatchId, MatchMapsScores>>(new Map())

export type MatchesScores = Map<MatchId, Scores | Record<string, never>>
export const matchesScores = ref<MatchesScores>(new Map())

let socket: any
let socketScoreBoard: any

let globalListsId: number[] = []
function arraysNoMatch(listIds: number[]) {
   const set1 = new Set(globalListsId)
   const set2 = new Set(listIds)

   for (const value of set2) {
      if (!set1.has(value)) return false
   }

   return true
}

export const startConnection = (listIds: number[]) => {
   if (arraysNoMatch(listIds)) return

   if (socket) {
      socket.close()
   }

   globalListsId = listIds
   if (listIds.length === 0) return

   socket = io.connect('wss://scorebot-lb.hltv.org', {
      agent: false
   })

   const initObject = JSON.stringify({
      token: '',
      listIds
   })

   let reconnected = false

   socket.on('connect', () => {
      if (!reconnected) {
         socket.emit('readyForScores', initObject)
      }

      socket.on('score', (data: SeriesRuntime) => {
         const scoresObject: MatchMapsScores = new Map()

         matchesScores.value.set(data.listId, data.wins)

         for (const mapData of Object.values(data.mapScores)) {
            if (mapsCatalog.has(mapData.map)) {
               const mapName = mapsCatalog.get(mapData.map)
               scoresObject.set(mapName!, mapData)
            }
         }
         matchesMapsScores.value.set(data.listId, scoresObject)
      })

      socket.on('reconnect', () => {
         reconnected = true
         socket.emit('readyForScores', initObject)
      })
   })
}

type PlayerScoreBoard = {
   steamId: string
   dbId: number
   name: string
   score: number
   deaths: number
   assists: number
   alive: boolean
   money: number
   damagePrRound: number
   hp: number
   kevlar: boolean
   helmet: boolean
   nick: string
   hasDefusekit: boolean
   primaryWeapon?: string
   advancedStats: {
      kast: number
      entryKills: number
      entryDeaths: number
      multiKillRounds: number
      oneOnXWins: number
      flashAssists: number
   }
}

type MatchHistoryScoreBoard = {
   type: string
   roundOrdinal: number
   survivingPlayers: number
}

type MatchDataScoreBoard = {
   TERRORIST: PlayerScoreBoard[]
   CT: PlayerScoreBoard[]
   ctMatchHistory: {
      firstHalf: MatchHistoryScoreBoard[]
      secondHalf: MatchHistoryScoreBoard[]
   }
   terroristMatchHistory: {
      firstHalf: MatchHistoryScoreBoard[]
      secondHalf: MatchHistoryScoreBoard[]
   }
   bombPlanted: boolean
   mapName: string
   terroristTeamName: string
   ctTeamName: string
   currentRound: number
   counterTerroristScore: number
   terroristScore: number
   ctTeamId: number
   tTeamId: number
   frozen: boolean
   live: boolean
   ctTeamScore: number
   tTeamScore: number
   startingCt: number
   startingT: number
}

export const roundStatus = ref<'RoundStart' | 'RoundEnd' | 'Target_Bombed' | 'Bomb_Defused' | 'BombPlanted'>()
export const logPlayers = ref<MatchDataScoreBoard>()
let timerInterval: any
export const timer = ref(0)
export const timerFormatted = computed(() => {
   let minutes: number | string = Math.floor(timer.value / 60)
   let remainingSeconds: number | string = timer.value % 60

   if (minutes < 10) minutes = '0' + minutes
   if (remainingSeconds < 10) remainingSeconds = '0' + remainingSeconds

   return `${minutes}:${remainingSeconds}`
})

export const startScoreBoard = (matchId: number) => {
   console.log('startScoreBoard')

   if (socketScoreBoard) {
      socketScoreBoard.close()
   }

   socketScoreBoard = io.connect('wss://scorebot-lb.hltv.org', {
      agent: false
   })

   const initObject = JSON.stringify({
      token: '',
      listId: matchId
   })
   let reconnected = false

   socketScoreBoard.on('connect', () => {
      if (!reconnected) {
         socketScoreBoard.emit('readyForMatch', initObject)
      }

      socketScoreBoard.on('scoreboard', (data: MatchDataScoreBoard) => {
         logPlayers.value = data
      })

      socketScoreBoard.on('log', (data: any) => {
         const log = JSON.parse(data).log
         const lastLog = log ? log.at() : undefined

         if (lastLog) {
            const key = Object.keys(lastLog)[0]

            switch (key) {
               case 'RoundStart': {
                  clearInterval(timerInterval)
                  roundStatus.value = 'RoundStart'
                  timer.value = 115
                  timerInterval = setInterval(() => {
                     timer.value = timer.value - 1
                     if (timer.value <= 0) {
                        clearInterval(timerInterval)
                     }
                  }, 1000)
                  break
               }

               case 'RoundEnd': {
                  const winType = Object.values(lastLog)[0].winType
                  clearInterval(timerInterval)
                  timer.value = 0
                  if (winType && winType === 'Target_Bombed') {
                     roundStatus.value = 'Target_Bombed'
                  } else if (winType && winType === 'Bomb_Defused') {
                     roundStatus.value = 'Bomb_Defused'
                  } else {
                     roundStatus.value = 'RoundEnd'
                  }
                  break
               }

               case 'BombPlanted': {
                  clearInterval(timerInterval)
                  roundStatus.value = 'BombPlanted'
                  timer.value = 40
                  timerInterval = setInterval(() => {
                     timer.value = timer.value - 1
                     if (timer.value <= 0) {
                        clearInterval(timerInterval)
                     }
                  }, 1000)
                  break
               }

               case 'BombDefused': {
                  clearInterval(timerInterval)
                  roundStatus.value = 'Bomb_Defused'
                  timer.value = 0
                  break
               }

               default: {
                  break
               }
            }
         }
      })

      socketScoreBoard.on('reconnect', () => {
         reconnected = true
         socketScoreBoard.emit('readyForScores', initObject)
      })
   })
}

export const stopScoreBoard = () => {
   console.log('stop score board')

   if (socketScoreBoard) {
      socketScoreBoard.close()
   }
}

// TODO: когда переключаюсь с матча на матч то сокеты не меняюстся
// TODO: listIds сделать глобальным и проверять было ли изменение. Что бы просто так не было реконектов

// {"log":[{"Kill":{"killerName":"device","killerNick":"device","killerSide":"CT","victimNick":"FalleN","victimName":"FalleN","victimSide":"TERRORIST","weapon":"awp","headShot":false,"eventId":3069503908,"victimX":-2864,"victimY":-2152,"killerX":-3650,"killerY":-2228,"killerId":7592,"victimId":2023,"penetrated":false,"throughSmoke":false,"noScope":false,"killerBlind":false}}]}
