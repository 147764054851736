<script setup lang="ts">
import { ref } from 'vue'
import { locale } from '@/i18n'
import { useUserStore } from '@/stores'
import { changeOnboardingCollapseStatusRequest } from '@/composables'
import * as amplitude from '@/composables/amplitude'
import { useAddCard } from '@/composables/add-card'
import Modal from '@/components/UI/Modal.vue'
import SecretStrategy from '@/components/SecretStrategy.vue'

const userStore = useUserStore()
const { addCardMutation } = useAddCard()
const changeOnboardingCollapseStatus = changeOnboardingCollapseStatusRequest()

const buttonAccess = () => {
   if (userStore.userNew) {
      amplitude.track(`click.trial`, 'where-begin-2')

      amplitude.track('click.choice-payment-provider', 'onboarding', locale.value === 'ru' ? 'ukassa' : 'stripe')
      addCardMutation()
   } else {
      amplitude.track(`click.secret`, 'where-begin-2')
      console.log('secret')
      modalSecretIsActive.value = true
   }
}

const modalSecretIsActive = ref(false)

// const refWb2 = ref<HTMLElement>()

// onMounted(() => {
//    if (refWb2.value) {
//       const widthItem = 304
//       const gapItem = 8
//       const widthWindow = document.body.clientWidth
//       const scrollLeftStart = refWb2.value.scrollLeft

//       const a = Math.floor(widthWindow / widthItem)

//       console.log(refWb2.value.clientWidth / refWb2.value.children.length)

//       refWb2.value.addEventListener('scrollend', (event) => {
//          const target = event.target as HTMLDivElement
//          const scrollLeft = target.scrollLeft

//          console.log(scrollLeft / (widthItem + gapItem) + 1 + a)
//       })
//    }
// })
</script>

<template>
   <div class="flex flex-col gap-y-20px w-full overflow-hidden">
      <div class="h-40px px-24px <tablet:px-12px <laptop:mx-16px bg-black-850 rounded-10px flex items-center gap-x-8px h3-semibold">
         <button
            class="h-20px px-12px border border-black-700 rounded-full flex items-center &hover:border-orange-900 group transition-border duration-200"
            @click="changeOnboardingCollapseStatus.set(true)"
         >
            <i class="i-custom-arrow-outline text-12px rotate-90 mr-1px &group-hover:text-orange-900 transition-color duration-200"></i>
         </button>
         {{ $t('kawLe8E3byKeaUitmS2iE') }}
      </div>
      <div class="wb2-container">
         <div class="wb2-item">
            <div class="wb2-item-description">
               <div class="wb-item-title">
                  {{ $t('Kf8RzlAGkZljNJKZjWX2N') }} <span class="text-yellow-700">{{ $t('emd_eM9xr85-W6KMPZkyA') }}</span>
                  <br />
                  {{ $t('FOFNylUEQ3AkfKYqzZx3F') }} <span class="text-yellow-700">{{ $t('v7BS7p_LfF82jDd7XtqgK') }}</span>
               </div>
               <div class="wb-item-line"></div>
               <div class="wb-item-text">
                  {{ $t('NthQPU0rqreCDIHxzXEna') }} <span class="text-yellow-700">{{ $t('q6ErqMd0Ku4Nu-cThCQtr') }}</span> —
                  <br />
                  {{ $t('r9bGpCxNNMp9T28NnUdQR') }} <span class="text-yellow-700">{{ $t('dlKsUWLJCtXbbPA_RYeGe') }}</span>
               </div>
            </div>

            <img
               src="/img/where-begin2/s1-1.png"
               srcset="/img/where-begin2/s1-1@2.png 2x"
               alt="s1-1"
               class="absolute left-20px bottom-12px -z-1"
            />
            <picture>
               <source media="(max-width: 1439px)" srcset="/img/where-begin2/s1-2m.png 1x, /img/where-begin2/s1-2m@2.png 2x" />
               <img
                  srcset="/img/where-begin2/s1-2@2.png 2x"
                  src="/img/where-begin2/s1-2.png"
                  alt="s1-2"
                  class="absolute left-192px <fullhd:left-179px <laptop:left-auto <laptop:right-0 top-70px -z-2"
               />
            </picture>
         </div>
         <div class="wb2-item justify-end">
            <div class="wb2-item-description mb-40px <laptop:mb-8px">
               <div class="wb-item-title">
                  {{ $t('cqw-2-IC09a2cF8NSQoEB') }}
                  <br />
                  <span class="text-yellow-700">{{ $t('vSJEbAL9wavw2le1HgHI5') }}</span> {{ $t('c2wdRYM3krbqu7xUHLupd') }}
               </div>
               <div class="wb-item-line"></div>
               <div class="wb-item-text">
                  {{ $t('0RgoCOCaVk2abO0g8FemA') }}
                  <br />
                  {{ $t('ME6zi0Fu9p_riD8cAZ85D') }} <span class="text-yellow-700">{{ $t('VsrSB0eXD2gw8Jw9NH4qN') }}</span>
               </div>
            </div>

            <div
               class="w-232px bg-gray-50/4 backdrop-blur-25 border border-gray-50/2 rounded-full px-8px py-1px text-green-500 absolute top-16px left-1/2 -translate-x-1/2 flexc gap-x-8px"
            >
               <i class="i-custom-check-outline text-8px"></i>
               <span class="s-semibold">{{ $t('Vy0LRpHrlybn0XJ9ky6SM') }}</span>
            </div>

            <img
               src="/src/assets//img/where-begin/s21.png"
               srcset="/src/assets/img/where-begin/s21@2.png 2x"
               alt="s21"
               class="absolute left-1/2 -translate-x-1/2 top-36px -z-1"
            />
            <picture>
               <source media="(max-width: 1439px)" srcset="/img/where-begin2/s2-2m.png 1x, /img/where-begin2/s2-2m@2.png 2x" />
               <img
                  srcset="/img/where-begin2/s2-2@2.png 2x"
                  src="/img/where-begin2/s2-2.png"
                  alt="s2-2"
                  class="absolute right-0 <fullhd:-right-12px <laptop:right-0 bottom-58px -z-2"
               />
            </picture>
            <picture>
               <source media="(max-width: 1439px)" srcset="/img/where-begin2/s2-3m.png 1x, /img/where-begin2/s2-3m@2.png 2x" />
               <img
                  srcset="/img/where-begin2/s2-3@2.png 2x"
                  src="/img/where-begin2/s2-3.png"
                  alt="s2-3"
                  class="absolute left-0 top-70px <fullhd:top-76px <laptop:top-70px -z-2"
               />
            </picture>
         </div>
         <div class="wb2-item">
            <div class="wb2-item-description">
               <div class="wb-item-title">
                  {{ $t('6_AlRcGWavTFzs-5zPcbI') }}
                  <br />
                  {{ $t('LXF7yVHGpEjU6zLbPXUYa') }} <span class="text-yellow-700">{{ $t('eUwI1Kli8S2MTmaM19jqH') }}</span>
                  {{ $t('A2EogWEqwZkrcEKAa9PK6') }}
                  <span class="text-yellow-700">{{ $t('Yvzm6RTVSv4TTct3GQXVM') }}</span>
               </div>
               <div class="wb-item-line"></div>
               <div class="wb-item-text">
                  {{ $t('a_YLrQ3b50IBL0kSsxbsy') }} <span class="text-yellow-700">{{ $t('7MTp9LYnUXxFtVJr9g05n') }}</span
                  >{{ $t('pgWYW12fWZxHg8bk_4aVZ') }}
                  <br />
                  {{ $t('EOWd3G_MFI5OFXDGzAXcJ') }} <span class="text-yellow-700">{{ $t('On_uXPai--Lxv3WzO9x-j') }}</span>
               </div>
            </div>

            <img
               src="/img/where-begin2/s3-1.png"
               srcset="/img/where-begin2/s3-1@2.png 2x"
               alt="s3-1"
               class="absolute right-0 bottom-0px -z-1"
            />
            <picture>
               <source media="(max-width: 1439px)" srcset="/img/where-begin2/s3-2m.png 1x, /img/where-begin2/s3-2m@2.png 2x" />
               <img
                  srcset="/img/where-begin2/s3-2@2.png 2x"
                  src="/img/where-begin2/s3-2.png"
                  alt="s3-2"
                  class="absolute top-70px right-0 -z-2"
               />
            </picture>
            <picture>
               <source media="(max-width: 1439px)" srcset="/img/where-begin2/s3-3m.png 1x, /img/where-begin2/s3-3m@2.png 2x" />
               <img
                  srcset="/img/where-begin2/s3-3@2.png 2x"
                  src="/img/where-begin2/s3-3.png"
                  alt="s3-3"
                  class="absolute bottom-58px left-0 -z-2"
               />
            </picture>
         </div>
         <div class="wb2-item wb2-glow justify-end">
            <div class="wb2-item-description mb-40px">
               <div class="wb-item-title">
                  <span class="text-green-500">{{ $t('98up2ZPeB2WrXy9QCYUHq') }}</span> {{ $t('CgsGNdEtoHLgd_En-i3Bu') }}
                  <br />
                  {{ $t('Mwv4QMPqSLX-gi9tZe_C8') }}
               </div>
               <div class="wb-item-line"></div>
               <div v-if="userStore.userNew" class="wb-item-text">
                  {{ $t('hCRSMDIb7McjSdJwdFyeM') }}
                  <br />
                  {{ $t('QVP35WjRNAIgMtBzbNONi') }} <span class="text-green-500">{{ $t('GXxdbasYkGt6r0JKsk5pd') }}</span>
                  {{ $t('YbhJSAbY1-snqR1L0R4ew') }}
               </div>
               <div v-else class="wb-item-text">
                  {{ $t('7elW7LIhpoPNqojv_4Y6L') }}
                  <br />
                  <span class="text-green-500">{{ $t('GXxdbasYkGt6r0JKsk5pd') }}</span> {{ $t('YbhJSAbY1-snqR1L0R4ew') }}
               </div>
            </div>

            <picture>
               <source media="(max-width: 1439px)" srcset="/img/where-begin2/s4m.png 1x, /img/where-begin2/s4m@2.png 2x" />
               <img srcset="/img/where-begin2/s4@2.png 2x" src="/img/where-begin2/s4.png" alt="s4" class="absolute right-0 top-0px -z-1" />
            </picture>

            <button
               class="btn-lava w-256px <fullhd:w-224px absolute bottom-16px left-1/2 -translate-x-1/2 m-semibold p-12px rounded-10px"
               @click="buttonAccess"
            >
               {{ userStore.userNew ? $t('IToJYnEvYm9O8MFuTN_t_') : $t('mVUfsy1-y1EAXt9l4M1YS') }}
            </button>
         </div>
      </div>
   </div>

   <Modal v-model="modalSecretIsActive">
      <SecretStrategy />
   </Modal>
</template>

<style scoped>
.wb2-container {
   --uno: 'grid grid-cols-4 gap-x-8px';
}
.wb2-item {
   --bg-x: 0%;
   --bg-y: 0%;
   --uno: 'h-360px relative py-36px px-24px z-1 rounded-10px overflow-hidden flex flex-col';

   background: radial-gradient(134.63% 141.42% at var(--bg-x) var(--bg-y), rgba(71, 73, 94, 0.2) 0%, rgba(29, 30, 36, 0) 100%), #1d1e24;
}
.wb2-item:nth-child(2) {
   --bg-x: 0%;
   --bg-y: 100%;
}
.wb2-item-description {
   --uno: 'flex flex-col gap-y-8px';
}
.wb-item-title {
   --uno: 'h3-semibold';
}
.wb-item-line {
   --uno: 'w-20px h-2px rounded-full bg-gray-800';
}
.wb-item-text {
   --uno: 's-medium2';
}
.wb2-glow {
   background: radial-gradient(116% 90% at 0% 100%, rgba(44, 194, 86, 0.12) 0%, rgba(29, 30, 36, 0) 100%), #1d1e24;
}

@screen lt-fullhd {
   .wb2-item {
      --uno: 'px-20px';
   }
}

@screen lt-laptop {
   .wb2-container {
      --uno: 'snap-x snap-mandatory scroll-p-16px w-full flex px-16px overflow-x-auto';
      -ms-overflow-style: none;
      scrollbar-width: none;
   }
   .wb2-container::-webkit-scrollbar {
      display: none;
   }

   .wb2-item {
      --uno: 'w-304px h-328px flex-shrink-0 snap-start px-24px';
   }
}
</style>
