export const chanceSucceeded = (
   chance: number[],
   odds: number[],
   team1Id: number,
   team2Id: number,
   winnerId: number,
   forTeamId: number
) => {
   const [team1Chance, team2Chance] = chance
   const [team1Odds, team2Odds] = odds

   if (team1Id === winnerId && (team1Chance > team2Chance || (team1Chance === team2Chance && team1Odds > team2Odds))) {
      return team1Id === forTeamId ? 'text-green-500' : 'text-red-700'
   } else if (team2Id === winnerId && (team1Chance < team2Chance || (team1Chance === team2Chance && team1Odds < team2Odds))) {
      return team2Id === forTeamId ? 'text-green-500' : 'text-red-700'
   }

   return 'text-brown-600'
}

export const team1HasMoreChance = (chance: number[], odds: number[]) => {
   const [team1Chance, team2Chance] = chance
   const [team1Odds, team2Odds] = odds

   if (team1Chance > team2Chance || (team1Chance === team2Chance && team1Odds > team2Odds)) {
      return true
   } else if (team1Chance < team2Chance || (team1Chance === team2Chance && team1Odds < team2Odds)) {
      return false
   }
}
