<script setup lang="ts">
import { computed } from 'vue'
import type { MapData } from '@/composables/socket-io'

type Props = {
   mapName: string
   pickLeft: boolean
   teamLogo: string
   picked: boolean
   live?: boolean
   winner?: boolean
   chance?: number[]
   score?: [number, number]
   big?: boolean
   stat: MapData | undefined
   team1Id: number
   team2Id: number
   team1Name: string
   team2Name: string
}

const props = withDefaults(defineProps<Props>(), {
   live: true,
   winner: false,
   chance: undefined,
   score: undefined,
   big: false
})

const leftChanceLarge = computed(() => (props.chance ? props.chance[0] > props.chance[1] : undefined))

const chanceMissed = computed(
   () =>
      props.winner &&
      props.score &&
      props.chance &&
      ((props.score[0] > props.score[1] && props.chance[0] < props.chance[1]) ||
         (props.score[0] < props.score[1] && props.chance[0] > props.chance[1]))
)

const mapOver = computed(() => props.stat?.mapOver)
const team1CT = computed(() => props.stat?.currentCtId === props.team1Id)
const winnerLeft = computed(() => {
   if (!props.stat) return undefined
   return props.stat.scores[props.team1Id] > props.stat.scores[props.team2Id]
})
</script>

<template>
   <div class="w-full">
      <div
         class="rounded-t-10px relative z-1 overflow-hidden uppercase grid grid-cols-[16px_1fr_16px] items-center justify-items-center px-6px"
         :class="big ? 'h-88px' : 'h-28px'"
      >
         <div class="absolute left-0 right-0 -z-1 map-image" :class="big ? '-top-32px' : '-top-50px'">
            <img
               :src="`/img/maps/${mapName}.jpg`"
               :srcset="`/img/maps/${mapName}@2.jpg 2x`"
               alt="logo text"
               class="w-full"
               :class="live ? 'grayscale' : ''"
            />
         </div>

         <div class="w-16px h-16px flexc">
            <img
               v-if="picked && pickLeft"
               v-tippy="$t('dSafg_6QK8d6cF3kTVQ4O') + ' ' + team1Name"
               :src="teamLogo"
               alt="team1"
               class="w-16px h-16px"
            />
         </div>
         {{ mapName }}
         <div class="w-16px h-16px flexc">
            <img
               v-if="picked && !pickLeft"
               v-tippy="$t('dSafg_6QK8d6cF3kTVQ4O') + ' ' + team1Name"
               :src="teamLogo"
               alt="team1"
               class="w-16px h-16px"
            />
         </div>
         <div class="glow"></div>
      </div>

      <div class="bg-black-800 p-6px s-semibold text-gray-800 grid grid-cols-[1fr_auto_1fr] items-center gap-x-12px relative">
         <span v-if="stat && !mapOver" class="justify-self-end">{{ team1CT ? 'CT' : 'T' }}</span>
         <div
            v-else-if="stat && winnerLeft !== undefined && winnerLeft"
            class="flex items-center gap-x-6px text-green-500 justify-self-end"
         >
            <span class="i-custom-crown-solid text-10px"></span>
            <span class="s-semibold">WIN</span>
         </div>
         <div v-else></div>
         <div class="w-140px py-2px px-6px bg-black-850 rounded-full flex items-center justify-between">
            <div class="w-4px h-4px rounded-full"></div>
            <div v-if="stat?.scores" class="flex-auto grid grid-cols-[4px_1fr_auto_1fr_4px] gap-x-4px">
               <div class="flex items-center">
                  <div v-if="!mapOver" class="w-4px h-4px rounded-full" :class="team1CT ? 'bg-blue-500' : 'bg-yellow-800'"></div>
               </div>
               <span class="text-right" :class="mapOver ? '' : team1CT ? 'text-blue-500' : 'text-yellow-800'">{{
                  stat.scores[team1Id]
               }}</span>
               <span>:</span>
               <span :class="mapOver ? '' : team1CT ? 'text-yellow-800' : 'text-blue-500'">{{ stat.scores[team2Id] }}</span>
               <div class="flex items-center">
                  <div v-if="!mapOver" class="w-4px h-4px rounded-full" :class="team1CT ? 'bg-yellow-800' : 'bg-blue-500'"></div>
               </div>
            </div>
            <div v-else class="grid grid-cols-[1fr_auto_1fr] gap-x-4px">
               <span>—</span>
               <span>:</span>
               <span>—</span>
            </div>
            <div class="w-4px h-4px rounded-full"></div>
         </div>
         <span v-if="stat && !mapOver">{{ team1CT ? 'T' : 'CT' }}</span>
         <div v-else-if="stat && winnerLeft !== undefined && !winnerLeft" class="flex items-center gap-x-6px text-green-500">
            <span class="s-semibold">WIN</span>
            <span class="i-custom-crown-solid text-10px"></span>
         </div>
         <div v-else></div>

         <div
            v-if="stat && mapOver && winnerLeft !== undefined"
            class="absolute bottom-0 w-1/2 h-1px bg-green-500"
            :class="winnerLeft ? 'left-0' : 'right-0'"
         ></div>
      </div>

      <div v-if="chance" class="grid grid-cols-[1fr_auto_1fr] items-center p-6px gradient-plate-900 rounded-b-10px gap-x-40px">
         <span class="justify-self-end" :class="chanceMissed ? 'text-brown-600' : leftChanceLarge ? 'text-green-500' : 'text-red-700'"
            >{{ chance[0] }}%</span
         >
         <div class="w-1px h-20px bg-black-700 rounded-full"></div>
         <span :class="chanceMissed ? 'text-brown-600' : leftChanceLarge ? 'text-red-700' : 'text-green-500'">{{ chance[1] }}%</span>
      </div>
      <div v-else class="grid grid-cols-[1fr_auto_1fr] items-center p-6px gradient-plate-900 rounded-b-10px gap-x-40px">
         <span class="i-custom-lock-solid text-16px text-gray-800 justify-self-end"></span>
         <div class="w-1px h-20px bg-black-700 rounded-full"></div>
         <span class="i-custom-lock-solid text-16px text-gray-800"></span>
      </div>
   </div>
</template>

<style scoped>
.map-image::after {
   content: '';
   --uno: 'bg-black-900/20 absolute inset-0 rounded-t-10px';
}
.map-image::before {
   content: '';
   background-image: var(--gradient-plate-900);
   --uno: 'absolute inset-0  opacity-20 rounded-t-10px';
}

.glow {
   --uno: 'absolute bottom-0 left-0 right-0 rounded-t-10px h-97px -z-1';
   background-image: radial-gradient(41.47% 43.75% at 49.84% 100%, rgba(222, 58, 30, 0.1) 0%, rgba(29, 30, 36, 0) 100%);
}
</style>
