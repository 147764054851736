<template>
   <div class="loading-bg"></div>
</template>

<style scoped>
.loading-bg {
   position: relative;
   overflow: hidden;
   z-index: 1;
}
.loading-bg::after {
   background: linear-gradient(
      90deg,
      hsla(0, 0%, 40%, 0),
      hsla(0, 0%, 40%, 0.2),
      hsla(0, 0%, 40%, 0)
   );
   -webkit-animation: loading 1.5s infinite;
   animation: loading 1.5s infinite;
   content: '';
   height: 100%;
   left: 0;
   position: absolute;
   right: 0;
   top: 0;
   transform: translateX(-100%);
}

@-webkit-keyframes loading {
   to {
      transform: translateX(100%);
   }
}
@keyframes loading {
   to {
      transform: translateX(100%);
   }
}
</style>
