<script setup lang="ts">
import { ref, onMounted, type Ref } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { getSuccessTracker } from '@/gql/requests'
import CircleGraph from '@/components/CircleGraph.vue'
import LoadingBG from '@/components/LoadingBG.vue'
import StatMonth from './StatMonth.vue'
import InputMoney from '@/components/UI/InputMoney.vue'

const { result, loading, onResult } = useQuery(getSuccessTracker)

const amount = ref<number | ''>(100)

const profit = ref(0)
const matchesSuccess = ref(0)

const widthGraphs = ref(156)
const strokeWidthGraphs = ref(10)

function startCounter(start: Ref<number>, end: number, speed = 2000, step = 1) {
   const duration = Math.round(speed / (end / step))
   const counter = setInterval(() => {
      if (start.value < end) {
         const buffer = start.value + step
         start.value = buffer > end ? end : buffer
      } else {
         clearInterval(counter)
      }
   }, duration)
}

onResult((result) => {
   startCounter(matchesSuccess, result.data.statistics.recommended.successful)
   const moneyWin = Math.round(
      result.data.statistics.recommended.successful * (result.data.statistics.recommended.avgCoff - 1) * (amount.value || 0) -
         (100 - result.data.statistics.recommended.successful) * (amount.value || 0)
   )
   startCounter(profit, moneyWin, 2000, 23)
})

onMounted(() => {
   if (window.innerWidth < 1440) {
      widthGraphs.value = 132
      strokeWidthGraphs.value = 8
   }
})

const onUpdateInputAmount = (event: Event) => {
   if (window.innerWidth < 1440 && event instanceof InputEvent) {
      const target = event.target as HTMLInputElement

      const span = document.createElement('span')
      span.style.visibility = 'hidden'
      span.style.position = 'absolute'
      span.style.whiteSpace = 'nowrap'

      span.textContent = String(amount.value)

      document.body.append(span)

      target.style.width = Math.min(span.offsetWidth, 100) + 'px'

      span.remove()
   }

   const success = result.value?.statistics.recommended.successful ?? 0
   const avgCoff = result.value?.statistics.recommended.avgCoff ?? 0

   profit.value = Math.round(success * (avgCoff - 1) * (amount.value || 0) - (100 - success) * (amount.value || 0))
}
</script>

<template>
   <article class="flex flex-col gap-y-20px <tablet:gap-y-16px overflow-hidden">
      <div class="px-20px py-8px rounded-10px bg-black-850 h3-semibold <laptop:mx-16px">Calculate your profit</div>
      <div class="laptop:h-180px rounded-20px laptop:bg-black-850 relative z-1 <laptop:mx-16px">
         <div class="h-full grid grid-cols-[257px_1fr] <laptop:grid-cols-1">
            <div class="grid grid-cols-[auto] justify-center self-center <laptop:hidden">
               <div class="l-semibold gap-x-8px flex items-center">
                  last
                  <hr class="flex-auto h-2px rounded-full gradient-lava-700" />
               </div>
               <div class="h2-semibold">100 tips</div>
            </div>
            <div
               class="rounded-l-full <laptop:rounded-10px relative z-1 overflow-hidden result-gradient flex items-center <laptop:flex-col gap-x-54px px-40px <laptop:gap-y-14px <laptop:px-16px <laptop:py-14px"
            >
               <div class="<laptop:hidden">
                  <div class="xs-medium text-gray-50/50 uppercase">AVERADGE ODDS</div>
                  <LoadingBG
                     v-if="loading"
                     class="h-20px mt-4px w-50px loading-bg-after overflow-hidden relative border border-gray-50/4 rounded-6px"
                  />
                  <div v-else class="h3-semibold">{{ result?.statistics.recommended.avgCoff }}</div>
               </div>

               <div class="flex gap-x-46px <laptop:gap-x-28px <laptop:grid <laptop:grid-cols-[134px_134px]">
                  <div class="flex flex-col items-center gap-y-14px">
                     <div class="relative w-156px h-156px <laptop:w-132px <laptop:h-132px flexc">
                        <div
                           class="w-128px h-128px <laptop:w-108px <laptop:h-108px rounded-full bg-black-850 border border-gray-50/8 relative flex flex-col items-center pt-12px"
                        >
                           <i class="i-custom-target-outline text-16px <laptop:text-12px text-green-500/50"></i>
                           <span class="h2-semibold <laptop:h3-semibold mt-12px mb-4px <laptop:mt-15px <laptop:mb-12px text-green-500">
                              <LoadingBG
                                 v-if="loading"
                                 class="w-65px h-28px <laptop:w-44px <laptop:h-14px my-5px loading-bg-after overflow-hidden relative border border-gray-50/4 rounded-6px"
                              />
                              <template v-else> {{ matchesSuccess }}% </template>
                           </span>

                           <span class="s-medium2 <laptop:xs-medium">winrate</span>

                           <div class="absolute inset-0 bg-gray-50/4 rounded-inherit">
                              <div class="absolute inset-0 result-bg-circle-green rounded-inherit"></div>
                           </div>
                        </div>
                        <template v-if="loading">
                           <div class="loading-bg-after rounded-full absolute -inset-5px overflow-hidden z-1"></div>
                           <div class="absolute -inset-5px border border-10px <laptop:border-8px rounded-full border-black-850"></div>
                        </template>
                        <template v-else>
                           <CircleGraph
                              :percent="result?.statistics.recommended.successful ?? 0"
                              :speed="2"
                              :stroke-width="strokeWidthGraphs"
                              :width="widthGraphs"
                              color="url(#grad1)"
                           >
                              <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
                                 <stop offset="0%" stop-color="#2CC256" />
                                 <stop offset="100%" stop-color="#194124" />
                              </linearGradient>
                           </CircleGraph>
                           <CircleGraph
                              :percent="100 - (result?.statistics.recommended.successful ?? 0)"
                              :speed="2"
                              :stroke-width="strokeWidthGraphs"
                              :width="widthGraphs"
                              color="url(#grad2)"
                              class="rotate-180"
                           >
                              <linearGradient id="grad2" x1="100%" y1="0" x2="0" y2="0" gradientUnits="userSpaceOnUse">
                                 <stop offset="0%" stop-color="#DF1C48" />
                                 <stop offset="30%" stop-color="#690E23" />
                              </linearGradient>
                           </CircleGraph>
                        </template>

                        <div class="absolute inset-0 rounded-full overflow-hidden -z-1">
                           <i class="i-custom-lines text-320px absolute -top-74px <laptop:-top-96px -left-30px text-brown-700"></i>
                           <i class="i-custom-lines text-320px absolute -bottom-58px <laptop:-bottom-103px -right-20px text-brown-700"></i>
                        </div>
                     </div>
                     <div class="flex items-center gap-x-8px laptop:hidden">
                        <span class="xs-medium text-gray-50/50 uppercase">Avg Odds</span>
                        <LoadingBG v-if="loading" class="w-30px h-17px overflow-hidden relative border border-gray-50/4 rounded-6px" />
                        <span v-else class="l-semibold leading-[100%]">{{ result?.statistics.recommended.avgCoff }}</span>
                     </div>
                  </div>
                  <div class="flex items-center <laptop:flex-col <laptop:gap-y-10px">
                     <div class="relative w-156px h-156px <laptop:w-132px <laptop:h-132px flexc z-1">
                        <div
                           class="w-128px h-128px <laptop:w-108px <laptop:h-108px rounded-full bg-black-850 border border-gray-50/8 relative flex flex-col items-center pt-12px"
                        >
                           <i class="i-custom-profit-outline text-16px <laptop:text-12px text-yellow-700/50"></i>
                           <span
                              class="<laptop:h3-semibold mt-12px mb-4px <laptop:mt-15px <laptop:mb-12px text-yellow-700"
                              :class="profit > 1000000 ? 'h3-semibold' : 'h2-semibold'"
                           >
                              <LoadingBG
                                 v-if="loading"
                                 class="w-65px h-28px my-5px <laptop:w-44px <laptop:h-14px loading-bg-after overflow-hidden relative border border-gray-50/4 rounded-6px"
                              />
                              <template v-else> ${{ profit }}</template>
                           </span>
                           <span class="s-medium2 <laptop:xs-medium">profit</span>

                           <div class="absolute inset-0 bg-gray-50/4 rounded-inherit">
                              <div class="absolute inset-0 result-bg-circle-yellow rounded-inherit"></div>
                           </div>
                        </div>
                        <template v-if="loading">
                           <div class="loading-bg-after rounded-full absolute -inset-5px overflow-hidden z-1"></div>
                           <div class="absolute -inset-5px border border-10px <laptop:border-8px rounded-full border-black-850"></div>
                        </template>
                        <template v-else>
                           <CircleGraph
                              :percent="88"
                              :speed="2"
                              :stroke-width="strokeWidthGraphs"
                              :width="widthGraphs"
                              color="url(#grad3)"
                              class="laptop:-rotate-90"
                           >
                              <linearGradient id="grad3" x1="100%" y1="0%" x2="0%" y2="0%" gradientUnits="userSpaceOnUse">
                                 <stop offset="0%" stop-color="#876D1A" />
                                 <stop offset="100%" stop-color="#443710" />
                              </linearGradient>
                           </CircleGraph>
                           <CircleGraph
                              :percent="12"
                              :speed="2"
                              :stroke-width="strokeWidthGraphs"
                              :width="widthGraphs"
                              color="#F1B90A"
                              class="rotate-90 <laptop:rotate-180"
                           />
                        </template>
                        <div class="absolute inset-0 rounded-full overflow-hidden -z-1">
                           <i class="i-custom-lines text-320px absolute -top-74px <laptop:-top-96px -left-30px text-brown-700"></i>
                           <i class="i-custom-lines text-320px absolute -bottom-58px <laptop:-bottom-103px -right-20px text-brown-700"></i>
                        </div>
                     </div>

                     <Transition
                        appear
                        enter-from-class="laptop:opacity-0 transition-left"
                        enter-active-class="duration-1000 delay-500 transition-all"
                     >
                        <label
                           v-if="!loading"
                           class="<laptop: pl-18px pr-5px py-5px <laptop:py-2px <laptop:px-12px rounded-r-10px rounded-l-2px <laptop:rounded-full bg-gray-50/4 border border-gray-50/2 <laptop:border-yellow-700/30 flex items-center gap-x-12px <laptop:gap-x-8px backdrop-blur-10"
                        >
                           <span class="s-medium <laptop:hidden">
                              Write <br />
                              your stake
                           </span>
                           <span class="text-10px font-500 laptop:hidden">Stake</span>
                           <hr class="w-1px h-20px <laptop:h-12px bg-gray-800 rounded-full" />
                           <div
                              class="laptop:w-112px laptop:py-7px laptop:px-12px l-semibold flex items-center laptop:bg-black-800/80 rounded-10px laptop:border border-yellow-700/30 &hover:border-yellow-700/50 transition-colors focus-visible:border-yellow-700/50 focus-within:border-yellow-700/50"
                           >
                              <i class="i-custom-stake-outline text-16px <laptop:text-12px text-yellow-700 flex-shrink-0"></i>
                              <span class="ml-12px laptop:mr-4px <laptop:ml-4px">$</span>
                              <InputMoney
                                 v-model="amount"
                                 :min="0"
                                 :max-length="4"
                                 :decimal-places="0"
                                 select-focus
                                 class="leading-0 <laptop:m-semibold outline-none bg-transparent <laptop:w-26px <laptop:min-w-26px <laptop:max-w-48px w-full h-full"
                                 @input="onUpdateInputAmount"
                              />
                           </div>
                        </label>
                     </Transition>
                  </div>
               </div>

               <img
                  src="/src/assets/img/success-tracker/lines.svg"
                  alt="lines"
                  class="absolute top-0 left-0 -z-1 pointer-events-none <laptop:hidden"
               />
            </div>
         </div>
         <div class="absolute inset-0 -z-1 overflow-hidden rounded-inherit pointer-events-none <laptop:hidden">
            <i class="i-custom-triangle-solid text-320px gradient-plate-900 absolute -top-60px -left-200px rotate-180"></i>
            <i class="i-custom-triangle-solid text-320px gradient-plate-900-reverse absolute -bottom-190px -left-30px"></i>
            <i class="i-custom-triangle-solid text-320px gradient-plate-900 absolute -top-60px -right-200px rotate-180 -scale-x-100"></i>
            <i class="i-custom-triangle-solid text-320px gradient-plate-900-reverse absolute -bottom-190px -right-30px"></i>
         </div>
      </div>
      <div
         v-if="loading"
         class="grid grid-cols-6 gap-x-10px <laptop:flex <laptop:overflow-x-auto no-scrollbar <laptop:snap-x <laptop:snap-mandatory <laptop:scroll-px-16px <laptop:px-16px"
      >
         <LoadingBG v-for="i in 6" :key="i" class="h-130px overflow-hidden relative border border-gray-50/4 rounded-6px bg-black-850" />
      </div>
      <div
         v-else
         class="grid grid-cols-6 gap-x-10px <laptop:flex <laptop:overflow-x-auto no-scrollbar <laptop:snap-x <laptop:snap-mandatory <laptop:scroll-px-16px <laptop:px-16px"
      >
         <StatMonth
            v-for="item in result?.statistics.lastSixMonths"
            :key="item.id"
            :amount="amount"
            :predictions="item.predictions"
            :winrate="Number(item.winrate)"
            :odds="Number(item.raisedPrematch)"
            :year="item.year"
            :month="Number(item.month)"
         />
      </div>
   </article>
</template>

<style scoped>
.result-bg-tip {
   background: radial-gradient(50.49% 50% at 50.49% 0%, rgba(241, 185, 10, 0.1) 0%, rgba(31, 32, 38, 0) 100%), rgba(234, 236, 242, 0.04);
}
.result-bg-circle-green {
   background: radial-gradient(50% 50% at 50% -4%, rgba(44, 194, 86, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
}
.result-bg-circle-yellow {
   background: radial-gradient(50% 50% at 50% -4%, rgba(241, 185, 10, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
}

.result-gradient::before {
   content: '';
   --uno: 'laptop:w-1022px absolute top-0 bottom-0 right-238px -z-1 rounded-inherit pointer-events-none';
   background-image: linear-gradient(90deg, rgba(41, 42, 51, 1) 0%, rgba(41, 42, 51, 0) 100%);
}

@screen laptop {
   .transition-left {
      transform: translateX(-10px);
   }
}

@screen lt-laptop {
   .glow-coef {
      position: relative;
      z-index: 1;
   }
   .glow-coef::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      background: radial-gradient(43% 42% at 50% 0%, rgba(241, 185, 10, 0.12) 0%, rgba(29, 30, 36, 0) 100%);
      z-index: -1;
   }
   .header-gradient {
      background: linear-gradient(180deg, #292a33 -56.71%, rgba(41, 42, 51, 0) 48.07%);
   }

   .result-gradient::before {
      --uno: 'left-0 right-0';
      background: linear-gradient(180deg, #292a33 -85.38%, rgba(41, 42, 51, 0) 185%);
   }

   .result-screens-gradient {
      background: linear-gradient(0deg, #292a33 -87.39%, rgba(41, 42, 51, 0) 72.9%);
   }
}
</style>
