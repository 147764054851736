<script setup lang="ts">
import { computed } from 'vue'
import dayjs from 'dayjs'
import { locale } from '@/i18n'
import Body from './Body.vue'
import { type ResponseBestMatches } from '@/gql/requests'

const props = defineProps<{
   match: ResponseBestMatches[0]
}>()

const dateDay = computed(() => dayjs(props.match.date).locale(locale.value).format('D MMMM'))
const dateTime = computed(() => dayjs(props.match.date).locale(locale.value).format('HH:mm'))
</script>

<template>
   <Body
      :id="match.id"
      :date="new Date(match.date)"
      :is-live="false"
      :team1="match.team1"
      :team2="match.team2"
      :odds="[match.odds?.matchOutcome.team1 ?? 0, match.odds?.matchOutcome.team2 ?? 0]"
      :chance="match.chance ? [match.chance[0], match.chance[1]] : undefined"
      :risky="!match.recommended"
      :comment="Boolean(match.comment?.all) || Boolean(match.comment?.team1) || Boolean(match.comment?.team2)"
      :expect-comment="false"
      :winner-id="match.winnerId ?? 0"
      :score="match.score ? [match.score[0], match.score[1]] : undefined"
      :best-match="true"
   >
      <div class="w-full flex justify-between gap-x-20px py-12px">
         <div
            class="flex-shrink-0 w-144px py-3px flex items-center justify-center gap-x-8px s-semibold text-gray-800 border border-gray-800 rounded-full"
         >
            <span>{{ dateDay }}</span>
            <div class="w-1px h-12px bg-gray-800 rounded-full"></div>
            <span>{{ dateTime }}</span>
         </div>
         <div class="relative z-1 flex items-center gap-x-12px overflow-hidden">
            <div v-tippy="match.event.name" class="flex items-center gap-x-8px overflow-hidden">
               <div class="flex-shrink-0 w-20px h-20px flexc">
                  <img :src="match.event.logo ?? '#'" alt="Event logo" />
               </div>
               <span class="uppercase truncate">{{ match.event.name }}</span>
            </div>
            <div class="flex-shrink-0 w-1px h-12px bg-black-700 rounded-full"></div>
            <span class="flex-shrink-0 uppercase">BO{{ match.bestOf }}</span>
         </div>
      </div>
      <div class="h-52px w-1/2 absolute bottom-0 right-0 rounded-10px glow"></div>
   </Body>
</template>

<style scoped>
.glow {
   background-image: radial-gradient(35.64% 62.5% at 66.75% 100%, #292a33 0%, #1d1e24 90%);
}
</style>
