<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'

const props = defineProps<{
   modelValue: boolean
   escOff?: boolean
   iconCloseOff?: boolean
   stopCloseOutside?: boolean
}>()

const emit = defineEmits<{
   (event: 'update:modelValue', value: boolean): void
}>()

const modalElement = ref<HTMLElement>()

onMounted(() => {
   const scrollWidth = window.innerWidth - document.body.clientWidth
   document.body.classList.add('lock')
   document.body.style.paddingRight = `${scrollWidth}px`
   modalElement.value?.focus()
})

onUnmounted(() => {
   document.body.style.paddingRight = '0px'
   document.body.classList.remove('lock')
})

const startClickIsActive = ref(false)
const endClick = (event: Event) => {
   if (props.stopCloseOutside) return

   if (startClickIsActive.value && event.target === modalElement.value) {
      emit('update:modelValue', false)
   }
   startClickIsActive.value = false
}
const startClick = (event: Event) => {
   if (event.target === modalElement.value) {
      startClickIsActive.value = true
   }
}
</script>
<template>
   <Teleport to="body">
      <Transition
         appear
         enter-from-class="opacity-0"
         enter-active-class="duration-500 transition-opacity"
         leave-active-class="duration-500 transition-opacity"
         leave-to-class="opacity-0"
      >
         <div
            v-if="modelValue"
            ref="modalElement"
            tabindex="0"
            class="fixed inset-0 flexc bg-black-900/80 p-16px focus:outline-none z-10 backdrop-blur-4 <laptop:mb-64px"
            @pointerup="endClick"
            @pointerdown="startClick"
            @keydown.esc="escOff ? '' : emit('update:modelValue', false)"
         >
            <Transition appear enter-from-class="scale-80" enter-active-class="duration-500 transition-transform">
               <div class="scroll-custom no-scrollbar max-h-full">
                  <div class="flex items-start gap-x-8px relative">
                     <slot />

                     <button
                        v-if="!iconCloseOff"
                        class="<laptop:absolute top-8px right-8px z-100"
                        @click="emit('update:modelValue', false)"
                     >
                        <button class="i-custom-cancel-outline laptop:text-white text-12px hover:text-opacity-80"></button>
                     </button>
                  </div>
               </div>
            </Transition>
         </div>
      </Transition>
   </Teleport>
</template>
